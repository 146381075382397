import { TranslateFunction } from 'react-utilities';
import { useForumPermissions } from '../contexts/ForumPermissionsContext';
import { usePost } from '../contexts/PostContext';

const useReplyDisabledState = ({
  translate
}: {
  translate: TranslateFunction;
}): { disabled: boolean; disabledTooltip?: string } => {
  const { canCreateComment } = useForumPermissions();
  const { post } = usePost();

  if (!canCreateComment) {
    return { disabled: true, disabledTooltip: translate('Description.NoReplyPermission') };
  }
  if (post?.isLocked) {
    return { disabled: true, disabledTooltip: translate('Description.NoReplyLocked') };
  }
  return { disabled: false };
};

export default useReplyDisabledState;
