import React, { ReactNode, useEffect } from 'react';
import classNames from 'classnames';
import useAppBumperVisibility from '../../shared/hooks/useAppBumperVisibility';

export type NativeFooterProps = {
  children: ReactNode;
  fixed?: boolean;
};

const NativeFooter = ({ fixed, children }: NativeFooterProps): JSX.Element => {
  const isBumperVisible = useAppBumperVisibility();

  useEffect(() => {
    if (!fixed) {
      return () => null;
    }
    // when the native footer is fixed, it overlaps the bottom of the screen
    // this class adds padding to the global page footer to prevent overlap
    document.getElementById('footer-container')?.classList.add('group-forums-page-footer');
    return () => {
      document.getElementById('footer-container')?.classList.remove('group-forums-page-footer');
    };
  }, [fixed]);

  return (
    <div
      className={classNames(
        'group-forums-native-footer',
        fixed && 'group-forums-native-footer-fixed',
        fixed && isBumperVisible && 'group-forums-native-footer-with-bumper'
      )}>
      {children}
    </div>
  );
};

export default NativeFooter;
