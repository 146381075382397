import React, {
  useState,
  useMemo,
  useRef,
  useEffect,
  useCallback,
  forwardRef,
  useImperativeHandle,
  KeyboardEvent
} from 'react';
import classNames from 'classnames';

export enum HotKeyType {
  Submit = 'HotKey:Submit'
}

const isSubmitHotKey = (event: KeyboardEvent<HTMLTextAreaElement>): boolean =>
  event.ctrlKey && event.key === 'Enter';

export type EditableContentFieldInputProps = {
  className?: string;
  textAreaClassName?: string;
  defaultValue?: string;
  placeholder?: string;
  maxLength?: number;
  fieldName?: string;
  showCharacterCount?: boolean;
  autoResize?: boolean;
  autoFocus?: boolean;
  maxTextFieldHeight?: number;
  locked?: boolean;
  validationError?: string;
  onChange?: (value: string) => void;
  onHotKey?: (hotKeyType: HotKeyType) => void;
};

export type EditableContentFieldHandle = {
  clearText: () => void;
  setText: (value: string) => void;
  focus: () => void;
};

const EditableContentFieldInput = forwardRef<
  EditableContentFieldHandle,
  EditableContentFieldInputProps
>(
  (props, ref): JSX.Element => {
    const {
      className,
      textAreaClassName,
      defaultValue,
      placeholder,
      maxLength = 1000,
      fieldName,
      showCharacterCount = true,
      autoResize = false,
      autoFocus = false,
      locked = false,
      maxTextFieldHeight = -1,
      validationError,
      onChange,
      onHotKey
    } = props;

    const [text, setText] = useState<string>('');
    const [isEdited, setIsEdited] = useState<boolean>(false);
    const textAreaRef = useRef<HTMLTextAreaElement>(null);

    const handleChange = useCallback(
      (value: string) => {
        setIsEdited(true);
        setText(value);
        onChange?.(value);
      },
      [onChange]
    );

    useImperativeHandle(ref, () => ({
      clearText: () => {
        if (textAreaRef.current) {
          textAreaRef.current.value = '';
        }
        setText('');
        setIsEdited(false);
        handleChange('');
      },
      setText: (value: string) => {
        if (textAreaRef.current) {
          textAreaRef.current.value = value;
        }
        handleChange(value);
      },
      focus: () => {
        textAreaRef.current?.focus();
      }
    }));

    const characterCountLabel = useMemo(() => {
      if (!maxLength) return null;
      return `${text.length}/${maxLength}`;
    }, [text, maxLength]);

    useEffect(() => {
      if (isEdited || !defaultValue) return;
      handleChange(defaultValue);
    }, [defaultValue, isEdited, handleChange]);

    useEffect(() => {
      if (!autoResize || !textAreaRef.current) return;
      textAreaRef.current.style.height = ''; // reset height for next calculation
      let newHeight = textAreaRef.current.scrollHeight + 3;
      if (maxTextFieldHeight > 0 && newHeight > maxTextFieldHeight) {
        newHeight = maxTextFieldHeight;
      }
      textAreaRef.current.style.height = `${newHeight}px`;
    }, [text, autoResize, maxTextFieldHeight]);

    useEffect(() => {
      if (autoFocus) {
        textAreaRef.current?.focus();
        const textLength = textAreaRef.current?.textLength ?? 0;
        textAreaRef.current?.setSelectionRange(textLength, textLength);
      }
    }, [autoFocus]);

    const handleKeyPress = (event: KeyboardEvent<HTMLTextAreaElement>) => {
      if (isSubmitHotKey(event)) {
        onHotKey?.(HotKeyType.Submit);
      }
    };

    return (
      <div className={classNames('editable-content-field-input', className)}>
        <textarea
          className={classNames('input-field', textAreaClassName)}
          name={fieldName}
          placeholder={placeholder}
          maxLength={maxLength}
          onChange={e => handleChange(e.target.value)}
          disabled={locked}
          onKeyPress={handleKeyPress}
          ref={textAreaRef}>
          {defaultValue}
        </textarea>
        <div className='editable-content-field-input-metadata'>
          {validationError && <p className='text-error'>{validationError}</p>}
          {showCharacterCount && characterCountLabel && (
            <p className='form-control-label small text'>{characterCountLabel}</p>
          )}
        </div>
      </div>
    );
  }
);

EditableContentFieldInput.displayName = 'EditableContentFieldInput';

export default EditableContentFieldInput;
