import { StateCreator } from 'zustand';
import { UiSlice, SetState } from './sliceTypes';

const createUiSlice: StateCreator<UiSlice, [], [], UiSlice> = (set: SetState<UiSlice>) => ({
  blockUserDialogContext: {
    userId: undefined
  },
  useInlineReply: false,
  categoryId: undefined,
  categoryName: undefined,
  postId: undefined,
  commentId: undefined,
  activeCommentId: undefined,
  threadCommentId: undefined,
  setCategoryId: (categoryId: string) =>
    set(
      {
        categoryId
      },
      undefined,
      'ui/setCategoryId'
    ),
  setActiveCommentId: (activeCommentId?: string) =>
    set(
      {
        activeCommentId
      },
      undefined,
      'ui/setActiveCommentId'
    ),
  openBlockDialog: (userId: number) =>
    set(
      {
        blockUserDialogContext: {
          userId
        }
      },
      undefined,
      'ui/openBlockDialog'
    ),
  closeBlockDialog: () =>
    set(
      {
        blockUserDialogContext: {
          userId: undefined
        }
      },
      undefined,
      'ui/closeBlockDialog'
    )
});

export default createUiSlice;
