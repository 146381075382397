import React from 'react';
import classNames from 'classnames';

type ForumSectionDisclaimerProps = {
  className?: string;
  iconClassName: string;
  heading: string;
  message: string;
  buttonText?: string;
  onClick?: () => void;
};

const ForumSectionDisclaimer = ({
  className,
  iconClassName,
  heading,
  message,
  buttonText,
  onClick
}: ForumSectionDisclaimerProps): JSX.Element => {
  return (
    <div className={classNames(className, 'forum-section-disclaimer', 'section-content-off')}>
      <span className={iconClassName} />
      <h2>{heading}</h2>
      <span>{message}</span>
      {buttonText && (
        <button type='button' className='btn-primary-md' onClick={onClick}>
          {buttonText}
        </button>
      )}
    </div>
  );
};

ForumSectionDisclaimer.defaultProps = {
  className: undefined,
  buttonText: undefined,
  onClick: undefined
};

export default ForumSectionDisclaimer;
