import { StateCreator } from 'zustand';
import { ContentSlice, SetState } from './sliceTypes';
import { ForumCategory } from '../types';
import forumsService from '../services/forumsService';

const createContentSlice: StateCreator<ContentSlice, [], [], ContentSlice> = (
  set: SetState<ContentSlice>,
  get
) => ({
  groupId: 0,
  userId: 0,
  categories: [],
  categoriesLoaded: false,
  pinnedPosts: [],
  categoryPosts: [],
  blockedUserList: [],
  blockUser: (userId: number) =>
    set(
      state => ({
        blockedUserList: [...state.blockedUserList, userId]
      }),
      undefined,
      'content/blockUser'
    ),
  clearBlockedUsers: () =>
    set(
      {
        blockedUserList: []
      },
      undefined,
      'content/clearBlockedUsers'
    ),
  loadCategories: async () => {
    const { groupId } = get();
    let categories: ForumCategory[] = [];

    try {
      const response = await forumsService.getGroupForumCategories(groupId, false);
      categories = response.data;
    } catch (e) {
      // we handle this in the Categories
    }

    set(
      {
        categories,
        categoriesLoaded: true
      },
      undefined,
      'content/loadCategories'
    );
  }
});

export default createContentSlice;
