import React from 'react';
import { withTranslations, WithTranslationsProps } from 'react-utilities';
import { groupsConfig } from '../translation.config';
import { CommentVariants } from '../constants/groupForumsConstants';
import CommentSkeleton from '../components/skeletons/CommentSkeleton';
import Comment from '../components/Comment';
import { useComposer } from '../contexts/ComposerContext';
import { ForumComment } from '../types';
import useForumStore from '../hooks/useForumStore';

export type CommentRepliesProps = {
  replies: ForumComment[];
  onShowReplies: () => void;
  onLoadPrevious: () => void;
  onLoadNext: () => void;
  isLoading: boolean;
  isFetchingMore: boolean;
  hasPrevious: boolean;
  hasNext: boolean;
  loadingError: boolean;
  parentId: string;
} & WithTranslationsProps;

const CommentReplies = ({
  replies,
  onShowReplies,
  onLoadPrevious,
  onLoadNext,
  isLoading,
  isFetchingMore,
  parentId,
  hasPrevious,
  hasNext,
  loadingError,
  translate
}: CommentRepliesProps): JSX.Element => {
  const { highlightedCommentId } = useComposer();
  const blockedUserList = useForumStore.use.blockedUserList();

  const handleShowRepliesKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      onShowReplies();
    }
  };

  const handleLoadMoreKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      onLoadNext();
    }
  };

  if (loadingError && (!replies || replies.length === 0)) {
    return (
      <div className='groups-forums-comment-replies-show-more'>
        <div
          role='button'
          tabIndex={0}
          onClick={onShowReplies}
          onKeyDown={handleShowRepliesKeyDown}
          className='groups-forums-comment-replies-show-more-btn'>
          <div className='groups-forums-comment-replies-show-more-dash' />
          {translate('Action.ShowReplies')}
        </div>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className='group-forums-comment-replies group-forums-comment-replies-loading'>
        <CommentSkeleton />
      </div>
    );
  }

  return (
    <div className='groups-forums-comment-replies group-forums-comment-replies-loaded'>
      {hasPrevious && !isFetchingMore && (
        <div className='groups-forums-comment-replies-show-more'>
          <div
            role='button'
            tabIndex={0}
            onClick={onLoadPrevious}
            className='groups-forums-comment-replies-show-more-btn'>
            <div className='groups-forums-comment-replies-show-more-dash' />
            {translate('Action.ShowMoreReplies')}
          </div>
        </div>
      )}
      {replies &&
        replies.map(reply => {
          const isActive = highlightedCommentId === reply.id;

          if (blockedUserList.length > 0 && blockedUserList.includes(reply.createdBy)) {
            return null;
          }

          return (
            <div key={reply.id} className='groups-forums-comment-reply'>
              <Comment
                key={reply.id}
                variant={CommentVariants.Reply}
                isActive={isActive}
                id={reply.id}
                createdBy={reply.createdBy}
                creatorInfo={reply.creatorInfo}
                createdAt={reply.createdAt}
                updatedAt={reply.updatedAt}
                content={reply.content.plainText}
                threadId={null}
                channelId={reply.parentId}
                parentCommentId={parentId}
                reactions={reply.reactions}
              />
            </div>
          );
        })}
      {hasNext && !isFetchingMore && (
        <div className='groups-forums-comment-replies-show-more'>
          <div
            role='button'
            tabIndex={0}
            onClick={onLoadNext}
            onKeyDown={handleLoadMoreKeyDown}
            className='groups-forums-comment-replies-show-more-btn'>
            <div className='groups-forums-comment-replies-show-more-dash' />
            {translate('Action.ShowMoreReplies')}
          </div>
        </div>
      )}
      {isFetchingMore && <div className='spinner spinner-default spinner-infinite-scroll' />}
    </div>
  );
};
export default withTranslations(CommentReplies, groupsConfig);
