import React, { memo, useCallback, useEffect } from 'react';
import { useSystemFeedback } from 'react-style-guide';
import { withTranslations, WithTranslationsProps } from 'react-utilities';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { groupsConfig } from '../translation.config';
import CategoriesList from './CategoriesList';
import groupForumsConstants from '../constants/groupForumsConstants';
import PostPreviewList from './PostPreviewList';
import CreatePostButton from '../components/CreatePostButton';
import NativeFooter from '../components/NativeFooter';
import { useForumPermissions } from '../contexts/ForumPermissionsContext';
import { logGroupForumsClickEvent } from '../utils/logging';
import ForumSectionDisclaimer from '../components/ForumSectionDisclaimer';
import useForumStore from '../hooks/useForumStore';
import useRouteValidation from '../hooks/useRouteValidation';

export type CategoriesProps = {} & WithTranslationsProps;

const Categories = memo(
  ({ translate }: CategoriesProps): JSX.Element => {
    const groupId = useForumStore.use.groupId();
    const categoryId = useForumStore.use.categoryId();
    const categories = useForumStore.use.categories();
    const categoriesLoaded = useForumStore.use.categoriesLoaded();
    const categoryName = useForumStore.use.categoryName();
    const loadCategories = useForumStore.use.loadCategories();
    useRouteValidation();
    const match = useRouteMatch();
    const history = useHistory();
    const { canCreatePost } = useForumPermissions();
    const { SystemFeedbackComponent } = useSystemFeedback();

    const setActiveCategory = useCallback(
      (nextCategoryId: string): void => {
        history.push(groupForumsConstants.router.getCategoryRoute(nextCategoryId));
        logGroupForumsClickEvent({
          groupId,
          clickTargetType: 'changeCategory',
          clickTargetId: nextCategoryId
        });
      },
      [groupId, history]
    );

    useEffect(() => {
      if (categoryId && match.path === groupForumsConstants.router.defaultRoute) {
        // redirect to first category
        setActiveCategory(categoryId);
      }
    }, [categoryId, match, setActiveCategory]);

    const fetchForumCategories = useCallback(() => {
      // eslint-disable-next-line no-void
      void loadCategories();
    }, [loadCategories]);

    const onCreatePost = useCallback(() => {
      if (categoryId) {
        history.push(groupForumsConstants.router.getPostCreateRoute(categoryId));
        logGroupForumsClickEvent({
          groupId,
          clickTargetType: 'goToPostComposerCreate',
          clickTargetId: categoryId
        });
      }
    }, [groupId, categoryId, history]);

    if (categoriesLoaded && !categories.length) {
      return (
        <ForumSectionDisclaimer
          iconClassName='icon-status-alert'
          heading={translate('Error.LoadCategoryTitle')}
          message={translate('Error.ReloadingSubtitle')}
          buttonText={translate('Action.RetryLoadingForums')}
          onClick={fetchForumCategories}
        />
      );
    }

    return (
      <div className='group-forums-categories'>
        <div className='group-forums-categories-list-container'>
          <CategoriesList onSetActiveCategory={setActiveCategory} />
        </div>
        <div className='group-forums-categories-header'>
          <h2 className='group-forums-categories-header-category-name'>{categoryName}</h2>
          <CreatePostButton
            disabled={!canCreatePost}
            label={translate('Action.CreatePost')}
            onClick={onCreatePost}
          />
        </div>
        <PostPreviewList />
        <NativeFooter fixed>
          <CreatePostButton
            disabled={!canCreatePost}
            label={translate('Action.CreatePost')}
            onClick={onCreatePost}
          />
        </NativeFooter>
        <SystemFeedbackComponent />
      </div>
    );
  }
);

Categories.displayName = 'Categories';

export default withTranslations(Categories, groupsConfig);
