import React from 'react';
import { Tooltip } from 'react-style-guide';

type ConditionalTooltipProps = {
  id: string;
  content: React.ReactNode;
  placement: string;
  containerClassName?: string;
  children: React.ReactNode;
  enabled: boolean;
};

const ConditionalTooltip = ({
  placement,
  content,
  children,
  id,
  containerClassName,
  enabled
}: ConditionalTooltipProps): JSX.Element => {
  return enabled ? (
    <Tooltip
      containerClassName={containerClassName}
      id={id}
      placement={placement}
      content={content}>
      {children}
    </Tooltip>
  ) : (
    <div className={containerClassName}>{children}</div>
  );
};

ConditionalTooltip.defaultProps = {
  containerClassName: undefined
};

export default ConditionalTooltip;
