import React, { FC, ReactNode, useEffect } from 'react';
import { HashRouter } from 'react-router-dom';
import { SystemFeedbackProvider } from 'react-style-guide';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { GroupPermissions } from '../../shared/types';
import { ForumPermissionsProvider } from '../contexts/ForumPermissionsContext';
import { EmotesProvider } from '../../shared/contexts/EmoteContext';
import useViewportSize from '../../shared/hooks/useViewportSize';
import { ForumExperimentsProvider } from '../contexts/ForumExperimentsContext';
import useForumStore from '../hooks/useForumStore';

export type Props = {
  children: ReactNode;
  permissions: GroupPermissions;
  userId: number;
  groupId: number;
  isGroupMember: boolean;
};

const queryClient = new QueryClient();

const Providers: FC<Props> = ({ children, permissions, groupId, userId, isGroupMember }) => {
  const { isSmallViewport } = useViewportSize();
  const hydrate = useForumStore.use.hydrate();

  // hydrate store
  useEffect(() => {
    hydrate({ groupId, userId, useInlineReply: !isSmallViewport });
  }, [isSmallViewport, groupId, userId, hydrate]);

  // Hash Type "hashbang" is needed to handle the #!/ in the url for group details tabs
  return (
    <SystemFeedbackProvider>
      <QueryClientProvider client={queryClient}>
        <EmotesProvider groupId={groupId}>
          <ForumExperimentsProvider>
            <ForumPermissionsProvider permissions={permissions} isGroupMember={isGroupMember}>
              <HashRouter hashType='hashbang'>{children}</HashRouter>
            </ForumPermissionsProvider>
          </ForumExperimentsProvider>
        </EmotesProvider>
      </QueryClientProvider>
    </SystemFeedbackProvider>
  );
};

export default Providers;
