import React, { useState } from 'react';
import { withTranslations, WithTranslationsProps } from 'react-utilities';
import { Button } from 'react-style-guide';
import { groupsConfig } from '../translation.config';

export type GroupWallDeprecationNoticeProps = {
  onShowWallPost: () => void;
  showCta: boolean;
} & WithTranslationsProps;

const GroupWallDeprecationNotice = ({
  translate,
  onShowWallPost,
  showCta
}: GroupWallDeprecationNoticeProps): JSX.Element => {
  const [isCTAClicked, setIsCTAClicked] = useState(false);

  const handleCTAClick = () => {
    setIsCTAClicked(true);
    onShowWallPost();
  };

  return (
    <div className='group-wall-deprecation-notice'>
      <span className='group-wall-deprecation-notice-icon icon-warning' />
      <div className='group-wall-deprecation-notice-content'>
        <h3 className='group-wall-deprecation-notice-title'>
          {translate('Heading.GroupWallDeprecated')}
        </h3>
        <div className='group-wall-deprecation-notice-description'>
          {translate('Description.GroupWallDeprecated')}
        </div>
      </div>
      {showCta && (
        <div className='group-wall-deprecation-notice-button'>
          <Button
            variant={Button.variants.primary}
            size={Button.sizes.small}
            width={Button.widths.full}
            onClick={handleCTAClick}
            isDisabled={isCTAClicked}>
            {translate('Action.SeeWall')}
          </Button>
        </div>
      )}
    </div>
  );
};

export default withTranslations(GroupWallDeprecationNotice, groupsConfig);
