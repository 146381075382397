import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSystemFeedback } from 'react-style-guide';
import { withTranslations, WithTranslationsProps } from 'react-utilities';
import { useQuery } from '@tanstack/react-query';
import { groupsConfig } from '../translation.config';
import TextContentEditor from '../components/content/TextContentEditor';
import CategoriesList from './CategoriesList';
import groupForumsConstants from '../constants/groupForumsConstants';
import forumsService from '../services/forumsService';
import { useForumPermissions } from '../contexts/ForumPermissionsContext';
import { logGroupForumsClickEvent } from '../utils/logging';
import useForumStore from '../hooks/useForumStore';

export type PostComposerProps = {
  defaultCategoryId: string;
  editingPostId?: string;
} & WithTranslationsProps;

const PostComposer = ({
  defaultCategoryId,
  editingPostId,
  translate
}: PostComposerProps): JSX.Element => {
  const history = useHistory();
  const groupId = useForumStore.use.groupId();
  const [activeCategoryId, setActiveCategoryId] = useState(defaultCategoryId);
  const [submitErrorKey, setSubmitErrorKey] = useState<string | null>(null);
  const { canCreatePost, canEditPost } = useForumPermissions();

  const { systemFeedbackService } = useSystemFeedback();
  const isEditing = !!editingPostId;
  const { isLoading, data: forumPost } = useQuery({
    queryFn: async () => {
      if (!editingPostId) {
        return undefined;
      }
      const response = await forumsService.getGroupForumPostsByIds(groupId, defaultCategoryId, [
        editingPostId
      ]);

      return response.data[0];
    },
    onError: () => systemFeedbackService.warning(translate('NetworkError')),
    enabled: isEditing
  });

  const onSubmit = useCallback(
    async ({ title, content }) => {
      try {
        if (editingPostId) {
          if (!forumPost) return;
          await forumsService.updateGroupForumComment(
            groupId,
            activeCategoryId,
            editingPostId,
            forumPost.firstComment.id,
            content
          );
          history.push(groupForumsConstants.router.getPostRoute(activeCategoryId, editingPostId));
        } else {
          const {
            categoryId: postCategoryId,
            id: postId
          } = await forumsService.createGroupForumPost(groupId, activeCategoryId, title, content);
          history.push(groupForumsConstants.router.getPostRoute(postCategoryId, postId));
        }

        const logEventData = editingPostId
          ? { clickTargetType: 'editPost', clickTargetId: editingPostId }
          : { clickTargetType: 'createPost', clickTargetId: activeCategoryId };

        logGroupForumsClickEvent({
          groupId,
          ...logEventData
        });
      } catch (error) {
        const typedError = error as { status: number };
        if (typedError.status === 400) {
          setSubmitErrorKey('Error.ForumPostModerated');
        } else {
          setSubmitErrorKey('NetworkError');
        }
      }
    },
    [editingPostId, activeCategoryId, groupId, forumPost, history]
  );

  const onBack = useCallback(() => {
    if (editingPostId) {
      history.push(groupForumsConstants.router.getPostRoute(defaultCategoryId, editingPostId));
    } else {
      history.push(groupForumsConstants.router.getCategoryRoute(defaultCategoryId));
    }
  }, [history, editingPostId, defaultCategoryId]);

  const onChange = useCallback(() => {
    if (!submitErrorKey) return;
    setSubmitErrorKey(null); // clear error key when user changes content
  }, [submitErrorKey]);

  useEffect(() => {
    if (!canCreatePost || (editingPostId && forumPost && !canEditPost(forumPost.createdBy))) {
      onBack();
    }
  }, [canCreatePost, canEditPost, editingPostId, forumPost, onBack]);

  const defaultTitle = forumPost?.name;
  const defaultContent = forumPost?.firstComment?.content.plainText;

  return (
    <div className='post-composer'>
      <TextContentEditor
        hasTitle
        headerText={translate(editingPostId ? 'Action.EditPost' : 'Action.CreatePost')}
        contentPlaceholder={translate('Label.WriteSomething')}
        submitText={translate('Action.Post')}
        customControls={
          <div className='post-composer-categories-control'>
            <h5 className='post-composer-categories-label'>{translate('Heading.Categories')}</h5>
            <div className='post-composer-categories-list'>
              <CategoriesList
                activeCategoryId={activeCategoryId}
                onSetActiveCategory={setActiveCategoryId}
                locked={isEditing}
              />
            </div>
          </div>
        }
        titleMaxLength={groupForumsConstants.limits.postTitleMaxLength}
        contentMaxLength={groupForumsConstants.limits.postContentMaxLength}
        defaultTitle={defaultTitle}
        defaultContent={defaultContent}
        titleLocked={isEditing}
        errorKey={submitErrorKey}
        onSubmit={onSubmit}
        onBack={onBack}
        onChange={onChange}
        isLoading={isEditing && isLoading}
      />
    </div>
  );
};

PostComposer.displayName = 'PostComposer';

export default withTranslations(PostComposer, groupsConfig);
