import groupForumsConstants from '../constants/groupForumsConstants';
import validation from '../../shared/utils/validation';

export const GetForumCategoryNameValidationErrorKey = (name: string): string | null => {
  if (name.length < groupForumsConstants.limits.categoryNameMinLength) {
    return 'Error.ForumCategoryNameValidationTooShort';
  }
  return null;
};

export const GetForumCategoryDescriptionValidationErrorKey = (
  description: string
): string | null => {
  if (description.length < groupForumsConstants.limits.categoryDescriptionMinLength) {
    return 'Error.ForumCategoryDescriptionValidationTooShort';
  }
  return null;
};

export const GetDeleteForumCategoryNameValidationErrorKey = (
  name: string,
  typedName: string
): string | null => {
  if (name !== typedName) {
    return 'Error.DeleteForumCategoryNameValidationNotEqual';
  }
  return null;
};

export const GetForumCategoryErrorMessageErrorKey = (errorMessage: string): string | null => {
  if (errorMessage.includes('InappropriateContent')) {
    return 'Error.CategoryNameInappropriateContent';
  }
  return null;
};

export const GetForumPostTitleValidationErrorKey = (title: string): string | undefined => {
  return validation.validateStringLength(
    title,
    'Error.ForumTitleValidationMinLength',
    groupForumsConstants.limits.postTitleMinLength
  );
};

export const GetForumPostContentValidationErrorKey = (content: string): string | undefined => {
  return validation.validateStringLength(
    content,
    'Error.ForumContentValidationMinLength',
    groupForumsConstants.limits.postContentMinLength
  );
};

export const GetForumCommentContentValidationErrorKey = (content: string): string | undefined => {
  return validation.validateStringLength(
    content,
    'Error.ForumContentValidationMinLength',
    groupForumsConstants.limits.commentContentMinLength
  );
};
