import { useCallback, useState } from 'react';
import { useSystemFeedback } from 'react-style-guide';
import { TranslateFunction } from 'react-utilities';
import { usePost } from '../contexts/PostContext';

type UseCommentSubmissionProps = {
  editingCommentId?: string;
  parentCommentId?: string;
  mentioningReplyId?: string;
  translate: TranslateFunction;
};

type UseCommentSubmissionReturn = {
  submitComment: (content: string) => Promise<boolean>;
  commentSubmissionError: string;
  clearCommentSubmissionError: () => void;
};

const useCommentSubmission = ({
  editingCommentId,
  parentCommentId,
  mentioningReplyId,
  translate
}: UseCommentSubmissionProps): UseCommentSubmissionReturn => {
  const { handleCreateComment, handleEditComment } = usePost();
  const { systemFeedbackService } = useSystemFeedback();
  const [commentSubmissionError, setCommentSubmissionError] = useState('');

  const handleCommentSubmissionError = useCallback(
    (error: unknown) => {
      const typedError = error as { status: number };
      if (typedError.status === 400) {
        setCommentSubmissionError(translate('Error.CommentModerationFailed'));
      } else {
        systemFeedbackService.warning(translate('NetworkError'));
      }
    },
    [translate, systemFeedbackService]
  );

  const clearCommentSubmissionError = useCallback(() => {
    setCommentSubmissionError('');
  }, []);

  const submitComment = useCallback(
    async (content: string) => {
      try {
        if (editingCommentId) {
          await handleEditComment({
            content,
            commentId: editingCommentId,
            parentCommentId
          });
        } else {
          await handleCreateComment({ content, parentCommentId, mentioningReplyId });
        }
        return true;
      } catch (error) {
        handleCommentSubmissionError(error);
        return false;
      }
    },
    [
      editingCommentId,
      parentCommentId,
      mentioningReplyId,
      handleCreateComment,
      handleEditComment,
      handleCommentSubmissionError
    ]
  );

  return {
    submitComment,
    commentSubmissionError,
    clearCommentSubmissionError
  };
};

export default useCommentSubmission;
