import React, { useState, createContext, useContext, useCallback, useEffect } from 'react';
import { ExperimentationService } from 'Roblox';
import groupForumsConstants from '../constants/groupForumsConstants';
import { layers } from '../../shared/constants/experimentConstants';
import { ForumNotificationsExperimentConfig, ForumExperimentsState } from '../types';

export const ForumExperimentsContext = createContext<ForumExperimentsState | undefined>(undefined);

export const useForumExperiments = (): ForumExperimentsState => {
  const resource = useContext(ForumExperimentsContext);
  if (!resource) {
    throw new Error('useForumExperiments must be used within a ForumExperimentsProvider');
  }
  return resource;
};

interface ForumExperimentsProviderProps {
  children: React.ReactNode;
}

export function ForumExperimentsProvider({ children }: ForumExperimentsProviderProps): JSX.Element {
  const [
    notificationsExperimentConfig,
    setNotificationsExperimentConfig
  ] = useState<ForumNotificationsExperimentConfig | null>(null);

  const fetchExperiments = useCallback(async () => {
    try {
      const response = await ExperimentationService.getAllValuesForLayer(layers.forumNotifications);
      const experimentConfig = response?.forumNotificationsConfig as ForumNotificationsExperimentConfig | null;
      setNotificationsExperimentConfig(experimentConfig);
    } catch (e) {
      setNotificationsExperimentConfig(null);
    }
  }, [setNotificationsExperimentConfig]);

  useEffect(() => {
    // eslint-disable-next-line no-void
    void fetchExperiments();
  }, [fetchExperiments]);

  return (
    <ForumExperimentsContext.Provider
      value={{
        notificationsExperimentConfig
      }}>
      {children}
    </ForumExperimentsContext.Provider>
  );
}
