import { useState, useCallback, useMemo } from 'react';

type UseCursoredDataProps<T> = {
  fetchItems: (
    cursor: string | null
  ) => Promise<{ data: T[]; nextPageCursor: string | null; previousPageCursor: string | null }>;
  initialCursor: string | null;
  compareFn: (itemA: T, itemB: T) => boolean;
  onAddItems?: (newItems: T[]) => void;
};

type UseCursoredDataReturn<T> = {
  items: T[];
  isLoadingInitialItems: boolean;
  isFetchingNextPage: boolean;
  isFetchingPreviousPage: boolean;
  error: boolean;
  fetchMore: () => void;
  fetchPrevious: () => void;
  refetch: () => void;
  addItems: ({ newItems, addToFront }: { newItems: T[]; addToFront: boolean }) => void;
  updateItem: (updatedItem: T) => void;
  hasMore: boolean;
  hasPrevious: boolean;
  setItems: (items: T[]) => void;
};

/**
 * @deprecated useCursoredData hook should should not be used for new code, please use `useInfiniteQuery`
 * @see PostPreviewList for example usage
 * @see {@link https://tanstack.com/query/v4/docs/framework/react/reference/useInfiniteQuery|react-query} react-query documentation
 */
const useCursoredData = <T,>({
  fetchItems,
  initialCursor,
  compareFn,
  onAddItems
}: UseCursoredDataProps<T>): UseCursoredDataReturn<T> => {
  const [items, setItems] = useState<T[]>([]);
  const [isLoadingInitialItems, setIsLoadingInitialItems] = useState<boolean>(true);
  const [isFetchingNextPage, setIsFetchingNextPage] = useState<boolean>(false);
  const [isFetchingPreviousPage, setIsFetchingPreviousPage] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [nextPageCursor, setNextPageCursor] = useState<string | null>(null);
  const [previousPageCursor, setPreviousPageCursor] = useState<string | null>(null);

  const addItems = useCallback(
    ({ newItems, addToFront }: { newItems: T[]; addToFront: boolean }) => {
      setItems(prevItems => {
        const uniqueNewItems = newItems.filter(
          newItem => !prevItems.some(item => compareFn(item, newItem))
        );

        if (uniqueNewItems.length === 0) return prevItems;
        onAddItems?.(uniqueNewItems);
        return addToFront ? [...uniqueNewItems, ...prevItems] : [...prevItems, ...uniqueNewItems];
      });
    },
    [compareFn, onAddItems]
  );

  const fetchInitialItems = useCallback(async () => {
    try {
      setIsLoadingInitialItems(true);
      setError(false);
      const response = await fetchItems(initialCursor);
      addItems({ newItems: response.data, addToFront: false });
      setNextPageCursor(response.nextPageCursor);
      setPreviousPageCursor(response.previousPageCursor);
    } catch {
      setError(true);
    } finally {
      setIsLoadingInitialItems(false);
    }
  }, [addItems, fetchItems, initialCursor]);

  const fetchMoreItems = useCallback(async () => {
    if (isFetchingNextPage) return;
    if (!nextPageCursor) return;
    try {
      setIsFetchingNextPage(true);
      setError(false);
      const response = await fetchItems(nextPageCursor);
      addItems({ newItems: response.data, addToFront: false });
      setNextPageCursor(response.nextPageCursor);
    } catch {
      setError(true);
    } finally {
      setIsFetchingNextPage(false);
    }
  }, [addItems, fetchItems, isFetchingNextPage, nextPageCursor]);

  const fetchPreviousItems = useCallback(async () => {
    if (isFetchingPreviousPage) return;
    if (!previousPageCursor) return;
    try {
      setIsFetchingPreviousPage(true);
      setError(false);
      const response = await fetchItems(previousPageCursor);
      addItems({ newItems: response.data, addToFront: true });
      setPreviousPageCursor(response.previousPageCursor);
    } catch {
      setError(true);
    } finally {
      setIsFetchingPreviousPage(false);
    }
  }, [addItems, fetchItems, isFetchingPreviousPage, previousPageCursor]);

  const updateItem = (updatedItem: T) => {
    setItems(prevItems => {
      const updatedItemIndex = prevItems.findIndex(item => compareFn(item, updatedItem));
      if (updatedItemIndex === -1) {
        return prevItems;
      }
      const newItems = [...prevItems];
      newItems[updatedItemIndex] = updatedItem;
      return newItems;
    });
  };

  const refetch = useCallback(() => {
    setItems([]); // Want to clear items before refetching
    // eslint-disable-next-line no-void
    void fetchInitialItems();
  }, [fetchInitialItems]);

  const hasMore = useMemo(() => {
    return nextPageCursor !== null && nextPageCursor.trim() !== '';
  }, [nextPageCursor]);

  const hasPrevious = useMemo(() => {
    return previousPageCursor !== null && previousPageCursor.trim() !== '';
  }, [previousPageCursor]);

  return {
    items,
    isLoadingInitialItems,
    isFetchingNextPage,
    isFetchingPreviousPage,
    error,
    fetchMore: fetchMoreItems,
    fetchPrevious: fetchPreviousItems,
    refetch,
    addItems,
    updateItem,
    hasMore,
    hasPrevious,
    setItems
  };
};

export default useCursoredData;
