import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { withTranslations, WithTranslationsProps } from 'react-utilities';
import { useSystemFeedback } from 'react-style-guide';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { InfiniteData, useInfiniteQuery } from '@tanstack/react-query';
import {
  Reaction,
  CommentCreatorInfo,
  ForumCommentsResponse,
  ForumThreadCommentsResponse,
  ForumComment
} from '../types';
import { groupsConfig } from '../translation.config';
import UserDisplay from './UserDisplay';
import CommentReactions from './CommentReactions';
import groupForumsConstants, { CommentVariants } from '../constants/groupForumsConstants';
import forumsService from '../services/forumsService';
import { usePost } from '../contexts/PostContext';
import { useComposer } from '../contexts/ComposerContext';
import CommentReplies from '../containers/CommentReplies';
import CommentComposer from './CommentComposer';
import AccessibleDivButton from '../../shared/components/AccessibleDivButton';
import { useForumPermissions } from '../contexts/ForumPermissionsContext';
import PostMenu from './PostMenu';
import CommentMenu from './CommentMenu';
import { logGroupForumsClickEvent } from '../utils/logging';
import useReplyDisabledState from '../hooks/useReplyDisabledState';
import ConditionalTooltip from '../../shared/components/ConditionalTooltip';
import ScrollFlashOverlay from './ScrollFlashOverlay';
import useForumStore from '../hooks/useForumStore';
import { getCommentRepliesKey } from '../services/queryKeys';

export type CommentProps = {
  id: string;
  createdBy: number;
  creatorInfo: CommentCreatorInfo;
  createdAt: string;
  updatedAt: string;
  content: string;
  title?: string;
  threadId: string | null; // The id of the channel with the thread of comments replying to this comment (if there is one)
  channelId: string; // The id of the channel this comment is in
  variant: CommentVariants;
  isActive: boolean;
  reactions: Reaction[];
  parentCommentId?: string;
  initialThreadComments?: ForumThreadCommentsResponse | null;
} & WithTranslationsProps;

const Comment = ({
  id,
  threadId,
  channelId,
  title,
  variant,
  isActive,
  createdBy,
  createdAt,
  updatedAt,
  content,
  parentCommentId,
  reactions,
  creatorInfo,
  initialThreadComments,
  translate
}: CommentProps): JSX.Element => {
  const history = useHistory();
  const { systemFeedbackService } = useSystemFeedback();
  const { post, togglePostNotifications, toggleCommentNotifications } = usePost();
  const { setReplyToCommentOrPost, setReplyToCommentReply } = useComposer();
  const { canReact } = useForumPermissions();
  const threadCommentId = useForumStore.use.threadCommentId();
  const [isQueryEnabled, setEnableQuery] = useState<boolean>(!!threadCommentId);
  const groupId = useForumStore.use.groupId();
  const categoryId = useForumStore.use.categoryId() || '';
  const postId = useForumStore.use.postId() || '';
  const activeCommentId = useForumStore.use.activeCommentId();
  const useInlineReply = useForumStore.use.useInlineReply();
  const setActiveCommentId = useForumStore.use.setActiveCommentId();

  const onDeletePost = () => {
    history.push(groupForumsConstants.router.getCategoryRoute(categoryId));
  };

  // infinite load category posts
  const {
    isLoading: isLoadingReplies,
    isFetchingPreviousPage: isFetchingPreviousRepliesPage,
    isFetchingNextPage: isFetchingNextRepliesPage,
    data: repliesResponse,
    fetchPreviousPage: fetchPreviousRepliesPage,
    fetchNextPage: fetchNextRepliesPage,
    isError,
    hasPreviousPage,
    hasNextPage
  } = useInfiniteQuery({
    queryKey: getCommentRepliesKey(groupId, categoryId, threadId || ''),
    queryFn: async ({ queryKey, pageParam: cursor }) => {
      const [, queryGroupId, queryCategoryId, queryThreadId] = queryKey;
      const response = await forumsService.getGroupForumComments(
        queryGroupId,
        queryCategoryId,
        queryThreadId,
        groupForumsConstants.pageCounts.commentsPerPage,
        cursor,
        threadCommentId
      );

      return response;
    },
    initialData: initialThreadComments
      ? ({
          pages: [
            {
              data: initialThreadComments.comments,
              nextPageCursor: initialThreadComments.nextPageCursor,
              previousPageCursor: initialThreadComments.previousPageCursor
            }
          ],
          pageParams: [initialThreadComments.nextPageCursor]
        } as InfiniteData<ForumCommentsResponse>)
      : undefined,
    getPreviousPageParam: (lastPage: ForumCommentsResponse) =>
      lastPage.previousPageCursor || undefined,
    getNextPageParam: (lastPage: ForumCommentsResponse) => lastPage.nextPageCursor || undefined,
    enabled: !!(groupId && categoryId && threadId) && isQueryEnabled
  });

  const replies = useMemo(() => {
    const pages = repliesResponse ? repliesResponse.pages : [];
    return pages.reduce((acc, response) => acc.concat(response.data), [] as ForumComment[]);
  }, [repliesResponse]);

  const hasPreviousReplies = useMemo(() => {
    const isFirstReplyVisible = !initialThreadComments
      ? false
      : !!initialThreadComments.comments.length &&
        !!replies.length &&
        initialThreadComments.comments[0].id !== replies[0].id;

    // if user has interacted with showing more, use server's opinion if its set
    if (isQueryEnabled && hasPreviousPage !== undefined) {
      return hasPreviousPage && isFirstReplyVisible;
    }

    // on initial load, if replies dont match initial comments, we have loaded into a threadCommentId deep link
    return isFirstReplyVisible;
  }, [hasPreviousPage, initialThreadComments, replies, isQueryEnabled]);

  const hasMoreReplies = useMemo(() => {
    // if user has interacted with showing more, use server's opinion if its set
    if (isQueryEnabled && hasNextPage !== undefined) {
      return hasNextPage;
    }

    // on initial load, we might have some preloaded comments
    return !!initialThreadComments?.hasMore;
  }, [hasNextPage, initialThreadComments, isQueryEnabled]);

  const showRepliesSection = useMemo(() => {
    return variant === CommentVariants.Comment && threadId !== null && replies.length > 0;
  }, [threadId, variant, replies]);

  const onToggleCommentReaction = async (emoteId: string, togglingOn: boolean) => {
    try {
      await forumsService.toggleGroupForumReaction(
        groupId,
        variant === CommentVariants.Reply ? channelId : postId,
        id,
        emoteId,
        togglingOn
      );
      logGroupForumsClickEvent({
        groupId,
        clickTargetType: `toggleCommentReaction${togglingOn ? 'On' : 'Off'}`,
        clickTargetId: emoteId
      });
      return true;
    } catch {
      systemFeedbackService.warning(translate('NetworkError'));
    }
    return false;
  };

  const handleShowRepliesClicked = useCallback(() => {
    setEnableQuery(true);

    logGroupForumsClickEvent({
      groupId,
      clickTargetType: 'showCommentReplies',
      clickTargetId: id
    });
  }, [groupId, id]);

  const handleGetNextRepliesClicked = useCallback(() => {
    if (!isQueryEnabled) {
      setEnableQuery(true);
    } else {
      // eslint-disable-next-line no-void
      void fetchNextRepliesPage();
    }
  }, [fetchNextRepliesPage, isQueryEnabled, setEnableQuery]);

  const handleGetPreviousRepliesClicked = useCallback(() => {
    // eslint-disable-next-line no-void
    void fetchPreviousRepliesPage();
  }, [fetchPreviousRepliesPage]);

  const handleReply = useCallback(() => {
    if (variant === CommentVariants.Reply && parentCommentId) {
      setReplyToCommentReply(parentCommentId, id);
      logGroupForumsClickEvent({
        groupId,
        clickTargetType: 'replyToReply',
        clickTargetId: id
      });
    } else if (variant !== CommentVariants.Reply) {
      setReplyToCommentOrPost(id);
      logGroupForumsClickEvent({
        groupId,
        clickTargetType: 'replyToComment',
        clickTargetId: id
      });
    }
  }, [groupId, id, parentCommentId, setReplyToCommentOrPost, setReplyToCommentReply, variant]);

  const handleMenuOpened = useCallback(() => {
    const isPost = variant === CommentVariants.Post;

    logGroupForumsClickEvent({
      groupId,
      clickTargetType: isPost ? 'openPostMenuFromComment' : 'openCommentMenu',
      clickTargetId: id
    });
  }, [id, variant, groupId]);

  useEffect(() => {
    if (isError) {
      systemFeedbackService.warning(translate('NetworkError'));
    }
  }, [systemFeedbackService, translate, isError]);

  const overflowButton = () => {
    return (
      <button
        type='button'
        className='group-forums-comment-dropdown-menu-button btn-generic-more-sm'
        title='more'
        onClick={handleMenuOpened}>
        <span className='group-forums-comment-overflow-icon' />
      </button>
    );
  };

  const toggleNotificationsCallback = useCallback(() => {
    try {
      if (variant === CommentVariants.Post) {
        togglePostNotifications();
      } else {
        toggleCommentNotifications(id);
      }
      systemFeedbackService.success(translate('Message.NotificationPreferenceUpdated'));
    } catch {
      systemFeedbackService.warning(translate('NetworkError'));
    }
  }, [
    id,
    variant,
    systemFeedbackService,
    togglePostNotifications,
    toggleCommentNotifications,
    translate
  ]);

  const renderMenu = () => {
    if (variant === CommentVariants.Post) {
      if (!post) return null;
      return (
        <PostMenu
          post={post}
          button={overflowButton()}
          onDelete={onDeletePost}
          onSubscribe={toggleNotificationsCallback}
        />
      );
    }

    return (
      <CommentMenu
        button={overflowButton()}
        isReply={variant === CommentVariants.Reply}
        createdBy={createdBy}
        commentId={id}
        parentCommentId={parentCommentId}
        threadId={threadId}
        channelId={channelId}
        onSubscribe={toggleNotificationsCallback}
      />
    );
  };

  const onScrollAnimationComplete = useCallback(() => {
    setActiveCommentId();
  }, [setActiveCommentId]);

  const { disabled: replyDisabled, disabledTooltip: replyDisabledTooltip } = useReplyDisabledState({
    translate
  });

  const showInlineCommentComposer = useInlineReply && isActive;

  const { displayName, hasVerifiedBadge, groupRoleName } = creatorInfo;

  const editedDate = createdAt !== updatedAt ? new Date(updatedAt) : undefined;

  return (
    <React.Fragment>
      <div
        className={classNames(
          'group-forums-comment',
          variant && `group-forums-comment-variant-${variant}`,
          isActive && 'group-forums-comment-active'
        )}
        data-id={id}>
        <div className='group-forums-comment-header'>
          <UserDisplay
            userId={createdBy}
            createdTime={createdAt}
            userDisplayName={displayName}
            hasVerifiedBadge={hasVerifiedBadge}
            groupRoleName={groupRoleName ?? translate('Label.FormerMember')}
          />
          <div className='group-forums-comment-menu'>{renderMenu()}</div>
        </div>
        {title && <h2 className='group-forums-comment-title'>{title.trim()}</h2>}
        <div className='group-forums-comment-content'>
          {content.trim()}
          {editedDate && (
            <span
              className='group-forums-comment-content-edited-marker'
              title={editedDate.toLocaleString()}>
              &ensp;{translate('Label.EditedMarker')}
            </span>
          )}
        </div>
        <div className='groups-forums-comment-metadata-section'>
          <div className='groups-forums-comment-metadata-reaction-section'>
            <CommentReactions
              initialReactions={reactions}
              onToggleReaction={onToggleCommentReaction}
              viewOnly={!canReact}
            />
          </div>
          {variant !== CommentVariants.Post && (
            <ConditionalTooltip
              id={`reply-tooltip-${id}`}
              placement='left'
              content={replyDisabledTooltip}
              enabled={replyDisabled}>
              <AccessibleDivButton
                onClick={!replyDisabled ? handleReply : undefined}
                className={classNames({
                  'groups-forums-comment-metadata-reply-section': true,
                  disabled: replyDisabled
                })}>
                <span className='group-forums-comment-reply-icon' />
                {translate('Action.Reply')}
              </AccessibleDivButton>
            </ConditionalTooltip>
          )}
        </div>
        {activeCommentId === id && <ScrollFlashOverlay onComplete={onScrollAnimationComplete} />}
      </div>
      {showInlineCommentComposer && (
        <div
          className={classNames(
            'group-forums-inline-comment-composer-container',
            variant === CommentVariants.Reply &&
              'group-forums-inline-comment-composer-reply-container',
            variant === CommentVariants.Comment &&
              'group-forums-inline-comment-composer-comment-container'
          )}>
          <div className='group-forums-inline-comment-composer'>
            <CommentComposer autoFocus showCancelButton />
          </div>
        </div>
      )}
      {showRepliesSection && (
        <CommentReplies
          replies={replies}
          onShowReplies={handleShowRepliesClicked}
          onLoadPrevious={handleGetPreviousRepliesClicked}
          onLoadNext={handleGetNextRepliesClicked}
          isLoading={isLoadingReplies}
          isFetchingMore={isFetchingNextRepliesPage || isFetchingPreviousRepliesPage}
          hasPrevious={hasPreviousReplies}
          hasNext={hasMoreReplies}
          loadingError={isError}
          parentId={id}
        />
      )}
    </React.Fragment>
  );
};

export default withTranslations(Comment, groupsConfig);
