import { useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import useForumStore from './useForumStore';

const useRouteValidation = (): void => {
  const match = useRouteMatch();
  const onRouteChange = useForumStore.use.onRouteChange();
  const categoriesLoaded = useForumStore.use.categoriesLoaded();

  useEffect(() => {
    // eslint-disable-next-line no-void
    void onRouteChange(match);
  }, [match, onRouteChange, categoriesLoaded]);

  return undefined;
};

export default useRouteValidation;
