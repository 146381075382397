import { httpService } from 'core-utilities';
import groupForumsConstants from '../constants/groupForumsConstants';
import {
  ForumAncestryResponse,
  ForumCategoriesResponse,
  ForumCategory,
  ForumComment,
  ForumCommentsResponse,
  ForumPost,
  ForumPostsResponse,
  NotificationPreference
} from '../types';

export default {
  getGroupForumCategories: async (
    groupId: number,
    archived: boolean
  ): Promise<ForumCategoriesResponse> => {
    const urlConfig = {
      url: groupForumsConstants.urls.getForumCategoriesEndpoint(groupId, archived),
      withCredentials: true
    };

    const response = await httpService.get<ForumCategoriesResponse>(urlConfig);

    const { data: categoriesResponse } = response;
    categoriesResponse.data.sort((a, b) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      return 0;
    });

    return categoriesResponse;
  },
  createGroupForumCategory: async (groupId: number, name: string): Promise<ForumCategory> => {
    const urlConfig = {
      url: groupForumsConstants.urls.getForumCategoriesEndpoint(groupId, false),
      withCredentials: true
    };

    const data = {
      name
    };

    const response = await httpService.post<ForumCategory>(urlConfig, data);
    return response.data;
  },
  deleteGroupForumCategory: async (
    groupId: number,
    forumCategoryId: string,
    archived: boolean
  ): Promise<void> => {
    const urlConfig = {
      url: groupForumsConstants.urls.getForumCategoryEndpoint(groupId, forumCategoryId, archived),
      withCredentials: true
    };

    await httpService.delete(urlConfig);
  },
  updateGroupForumCategory: async (
    groupId: number,
    forumCategoryId: string,
    name: string
  ): Promise<ForumCategory> => {
    const urlConfig = {
      url: groupForumsConstants.urls.getForumCategoryEndpoint(groupId, forumCategoryId),
      withCredentials: true
    };

    const data = {
      name
    };

    const response = await httpService.patch<ForumCategory>(urlConfig, data);
    return response.data;
  },
  getGroupForumPostsByIds: async (
    groupId: number,
    categoryId: string,
    postIds: string[]
  ): Promise<ForumPostsResponse> => {
    const urlConfig = {
      url: groupForumsConstants.urls.getForumPostsByIdsEndpoint(groupId, categoryId, postIds),
      withCredentials: true
    };

    const response = await httpService.get<ForumPostsResponse>(urlConfig);
    return response.data;
  },
  getGroupForumPosts: async (
    groupId: number,
    categoryId: string,
    limit: number,
    cursor?: string
  ): Promise<ForumPostsResponse> => {
    const urlParams = new URLSearchParams();
    urlParams.append('limit', limit.toString());
    if (cursor) {
      urlParams.append('cursor', cursor);
    }
    const urlConfig = {
      url: `${groupForumsConstants.urls.getForumPostsEndpoint(
        groupId,
        categoryId,
        true
      )}&${urlParams.toString()}`,
      withCredentials: true
    };

    const response = await httpService.get<ForumPostsResponse>(urlConfig);
    return response.data;
  },
  getGroupForumComments: async (
    groupId: number,
    categoryId: string,
    postId: string,
    limit: number,
    cursor: string | null,
    commentId?: string
  ): Promise<ForumCommentsResponse> => {
    const urlParams = new URLSearchParams();
    urlParams.append('limit', limit.toString());
    if (cursor) {
      urlParams.append('cursor', cursor);
    }
    if (commentId) {
      urlParams.append('commentId', commentId);
    }
    const urlConfig = {
      url: `${groupForumsConstants.urls.getForumCommentsEndpoint(
        groupId,
        categoryId,
        postId
      )}?${urlParams.toString()}`,
      withCredentials: true
    };

    const response = await httpService.get<ForumCommentsResponse>(urlConfig);
    return response.data;
  },
  archiveGroupForumCategory: async (
    groupId: number,
    forumCategoryId: string,
    isArchived: boolean
  ): Promise<void> => {
    const urlConfig = {
      url: groupForumsConstants.urls.getArchiveForumCategoryEndpoint(groupId, forumCategoryId),
      withCredentials: true
    };

    const data = {
      isArchived
    };

    await httpService.patch(urlConfig, data);
  },
  getGroupForumPinnedPosts: async (
    groupId: number,
    categoryId: string
  ): Promise<ForumPostsResponse> => {
    const urlConfig = {
      url: groupForumsConstants.urls.getForumPinnedPostsEndpoint(groupId, categoryId, true),
      withCredentials: true
    };

    const response = await httpService.get<ForumPostsResponse>(urlConfig);
    return response.data;
  },
  createGroupForumPost: async (
    groupId: number,
    categoryId: string,
    title: string,
    content: string
  ): Promise<ForumPost> => {
    const urlConfig = {
      url: groupForumsConstants.urls.getForumPostsEndpoint(groupId, categoryId, false),
      withCredentials: true
    };

    const data = {
      title,
      content
    };

    const response = await httpService.post<ForumPost>(urlConfig, data);
    return response.data;
  },
  updateGroupForumComment: async (
    groupId: number,
    categoryId: string,
    postId: string,
    commentId: string,
    content: string
  ): Promise<ForumComment> => {
    const urlConfig = {
      url: groupForumsConstants.urls.forumCommentEndpoint(groupId, categoryId, postId, commentId),
      withCredentials: true
    };

    const data = {
      content
    };

    const response = await httpService.patch<ForumComment>(urlConfig, data);
    return response.data;
  },
  toggleGroupForumPostPin: async (
    groupId: number,
    categoryId: string,
    postId: string,
    isPinned: boolean
  ): Promise<void> => {
    const urlConfig = {
      url: groupForumsConstants.urls.toggleForumPostPinEndpoint(groupId, categoryId, postId),
      withCredentials: true
    };

    const data = {
      isPinned
    };

    await httpService.patch(urlConfig, data);
  },
  toggleGroupForumPostLock: async (
    groupId: number,
    categoryId: string,
    postId: string,
    isLocked: boolean
  ): Promise<void> => {
    const urlConfig = {
      url: groupForumsConstants.urls.toggleForumPostLockEndpoint(groupId, categoryId, postId),
      withCredentials: true
    };

    const data = {
      isLocked
    };

    await httpService.patch(urlConfig, data);
  },
  deleteGroupForumPost: async (
    groupId: number,
    categoryId: string,
    postId: string
  ): Promise<void> => {
    const urlConfig = {
      url: groupForumsConstants.urls.deleteForumPostEndpoint(groupId, categoryId, postId),
      withCredentials: true
    };

    await httpService.delete(urlConfig);
  },
  createGroupForumComment: async (
    groupId: number,
    categoryId: string,
    postId: string,
    content: string,
    repliesToPostCommentId?: string
  ): Promise<ForumComment> => {
    const urlConfig = {
      url: groupForumsConstants.urls.getForumCommentsEndpoint(groupId, categoryId, postId),
      withCredentials: true
    };

    const data = {
      content,
      repliesToPostCommentId
    };

    const response = await httpService.post<ForumComment>(urlConfig, data);
    return response.data;
  },
  deleteGroupForumComment: async (
    groupId: number,
    categoryId: string,
    postId: string,
    commentId: string
  ): Promise<void> => {
    const urlConfig = {
      url: groupForumsConstants.urls.forumCommentEndpoint(groupId, categoryId, postId, commentId),
      withCredentials: true
    };

    await httpService.delete(urlConfig);
  },
  toggleGroupForumReaction: async (
    groupId: number,
    channelId: string,
    commentId: string,
    emoteId: string,
    togglingOn: boolean
  ): Promise<void> => {
    const urlConfig = {
      url: groupForumsConstants.urls.getForumCommentReactionsEndpoint(
        groupId,
        channelId,
        commentId,
        emoteId
      ),
      withCredentials: true
    };
    if (togglingOn) {
      await httpService.post(urlConfig);
    } else {
      await httpService.delete(urlConfig);
    }
  },
  markGroupForumPostAsRead: async (
    groupId: number,
    categoryId: string,
    postId: string,
    commentId: string
  ): Promise<void> => {
    const urlConfig = {
      url: groupForumsConstants.urls.markForumPostAsReadEndpoint(
        groupId,
        categoryId,
        postId,
        commentId
      ),
      withCredentials: true
    };

    const data = {
      isRead: true
    };

    await httpService.patch(urlConfig, data);
  },
  getPostNotificationPreference: async (
    groupId: number,
    categoryId: string,
    postId: string
  ): Promise<NotificationPreference> => {
    const urlConfig = {
      url: groupForumsConstants.urls.getPostNotificationPreferenceEndpoint(
        groupId,
        categoryId,
        postId
      ),
      withCredentials: true
    };

    const response = await httpService.get<NotificationPreference>(urlConfig);
    return response.data;
  },
  getCommentNotificationPreference: async (
    groupId: number,
    categoryId: string,
    postId: string,
    commentId: string
  ): Promise<NotificationPreference> => {
    const urlConfig = {
      url: groupForumsConstants.urls.getCommentNotificationPreferenceEndpoint(
        groupId,
        categoryId,
        postId,
        commentId
      ),
      withCredentials: true
    };

    const response = await httpService.get<NotificationPreference>(urlConfig);
    return response.data;
  },
  togglePostNotificationSubscription: async (
    groupId: number,
    categoryId: string,
    postId: string,
    isSubscribed: boolean
  ): Promise<void> => {
    const urlConfig = {
      url: groupForumsConstants.urls.getPostNotificationPreferenceEndpoint(
        groupId,
        categoryId,
        postId
      ),
      withCredentials: true
    };

    const data = {
      isSubscribed
    };

    await httpService.patch(urlConfig, data);
  },
  toggleCommentNotificationSubscription: async (
    groupId: number,
    categoryId: string,
    postId: string,
    commentId: string,
    isSubscribed: boolean
  ): Promise<void> => {
    const urlConfig = {
      url: groupForumsConstants.urls.getCommentNotificationPreferenceEndpoint(
        groupId,
        categoryId,
        postId,
        commentId
      ),
      withCredentials: true
    };

    const data = {
      isSubscribed
    };

    await httpService.patch(urlConfig, data);
  },
  getForumAncestry: async (
    groupId: number,
    categoryId: string,
    channelId: string,
    commentId: string
  ): Promise<ForumAncestryResponse> => {
    const urlConfig = {
      url: groupForumsConstants.urls.getForumAncestryEndpoint(
        groupId,
        categoryId,
        channelId,
        commentId
      ),
      withCredentials: true
    };

    const response = await httpService.get<ForumAncestryResponse>(urlConfig);
    return response.data;
  }
};
