import React, { useCallback, useRef } from 'react';
import { withTranslations, WithTranslationsProps } from 'react-utilities';
import classNames from 'classnames';
import { groupsConfig } from '../translation.config';
import ContentComposer from './content/ContentComposer';
import { logGroupForumsClickEvent } from '../utils/logging';
import { EditableContentFieldHandle } from './content/EditableContentFieldInput';
import useReplyDisabledState from '../hooks/useReplyDisabledState';
import ConditionalTooltip from '../../shared/components/ConditionalTooltip';
import useForumStore from '../hooks/useForumStore';
import useCommentSubmission from '../hooks/useCommentSubmission';

const DesktopPersistentComposer = ({ translate }: WithTranslationsProps): JSX.Element => {
  const groupId = useForumStore.use.groupId();
  const postId = useForumStore.use.postId();
  const inputRef = useRef<EditableContentFieldHandle>(null);

  const {
    submitComment,
    commentSubmissionError,
    clearCommentSubmissionError
  } = useCommentSubmission({ translate });

  const handleOnSubmit = useCallback(
    async (content: string) => {
      const logEventData = { clickTargetType: 'createComment', clickTargetId: postId };
      logGroupForumsClickEvent({
        groupId,
        ...logEventData
      });

      const success = await submitComment(content);
      if (success) {
        inputRef.current?.clearText();
      }
    },
    [groupId, postId, submitComment]
  );

  const handleOnChange = useCallback(
    (value: string) => {
      if (commentSubmissionError) {
        clearCommentSubmissionError();
      }
    },
    [commentSubmissionError, clearCommentSubmissionError]
  );

  const { disabled, disabledTooltip } = useReplyDisabledState({ translate });

  return (
    <ConditionalTooltip
      containerClassName='desktop-persistent-composer-container'
      id='desktop-persistent-composer-tooltip'
      placement='top'
      content={disabledTooltip}
      enabled={disabled}>
      <div className={classNames('desktop-persistent-composer', disabled && 'disabled')}>
        <ContentComposer
          autoFocus={false}
          errorMessage={commentSubmissionError}
          disabled={disabled}
          submitDisabled={!!commentSubmissionError}
          onChange={handleOnChange}
          onSubmit={handleOnSubmit}
          inputRef={inputRef}
        />
      </div>
    </ConditionalTooltip>
  );
};

export default withTranslations(DesktopPersistentComposer, groupsConfig);
