import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Group, GroupPermissions } from '../../shared/types';
import Categories from './Categories';
import PostComposer from './PostComposer';
import Providers from './Providers';
import groupForumsConstants from '../constants/groupForumsConstants';
import Post from './Post';
import BlockUserModal from '../components/dialogs/BlockUserDialog';
import GroupForumsEarlyAccessNotice from '../components/GroupForumsEarlyAccessNotice';

export type GroupForumsProps = {
  group: Group;
  permissions: GroupPermissions;
  userId: number;
  isGroupMember: boolean;
  isEnabled: boolean;
};

const GroupForums = ({
  group,
  permissions,
  userId,
  isGroupMember,
  isEnabled
}: GroupForumsProps): JSX.Element | null => {
  if (!isEnabled) return null;
  if (!group?.id) return null;

  return (
    <Providers
      permissions={permissions}
      groupId={group.id}
      userId={userId}
      isGroupMember={isGroupMember}>
      <div className='section group-forums'>
        <GroupForumsEarlyAccessNotice />
        <Switch>
          <Route
            path={groupForumsConstants.router.postEditRoute}
            render={(routeProps: { match: { params: { categoryId: string; postId: string } } }) => {
              return (
                <PostComposer
                  defaultCategoryId={routeProps.match.params.categoryId}
                  editingPostId={routeProps.match.params.postId}
                />
              );
            }}
          />
          <Route
            path={groupForumsConstants.router.postCreateRoute}
            render={(routeProps: { match: { params: { categoryId: string } } }) => {
              return <PostComposer defaultCategoryId={routeProps.match.params.categoryId} />;
            }}
          />
          <Route path={groupForumsConstants.router.postCommentRoute}>
            <div className='group-forums-post-wrapper'>
              <Post />
            </div>
          </Route>
          <Route path={groupForumsConstants.router.postRoute}>
            <div className='group-forums-post-wrapper'>
              <Post />
            </div>
          </Route>
          <Route path={groupForumsConstants.router.categoryRoute}>
            <Categories />
          </Route>
          <Route path={groupForumsConstants.router.defaultRoute}>
            <Categories />
          </Route>
        </Switch>
      </div>
      <BlockUserModal />
    </Providers>
  );
};

export default GroupForums;
