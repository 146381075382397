import React, { useCallback } from 'react';
import classNames from 'classnames';
import { ForumCategory } from '../types';
import groupForumsConstants from '../constants/groupForumsConstants';

export type CategoryPillProps = {
  category: ForumCategory;
  isActive: boolean;
  onClick?: (newCategoryId: string) => void;
};

const CategoryPill = ({ category, isActive, onClick }: CategoryPillProps): JSX.Element => {
  const handleClick = useCallback(
    (event?: React.MouseEvent) => {
      if (isActive) return;
      // suppress href native click
      if (event) {
        event.preventDefault();
      }

      onClick?.(category.id);
    },
    [isActive, category.id, onClick]
  );

  // Handle keyboard interactions
  const handleKeyDown = (event: React.KeyboardEvent<HTMLAnchorElement>) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      handleClick();
    }
  };

  return (
    <a
      role='button'
      tabIndex={0}
      className={classNames(
        'group-forums-category-pill',
        isActive && 'active',
        !!onClick && 'clickable'
      )}
      href={groupForumsConstants.deepLinks.groupForumCategoryUrl(category.groupId, category.id)}
      onClick={!isActive ? handleClick : undefined}
      onKeyDown={!isActive ? handleKeyDown : undefined}>
      {category.name}
    </a>
  );
};

export default CategoryPill;
