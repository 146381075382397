import React, { useCallback, useState, useMemo } from 'react';
import { withTranslations, WithTranslationsProps } from 'react-utilities';
import { IconButton, Popover } from 'react-style-guide';
import { groupsConfig } from '../translation.config';
import { Group } from '../../shared/types';
import { ForumCategory } from '../types';
import CreateOrUpdateForumCategoryDialog from './dialogs/CreateOrUpdateForumCategoryDialog';
import DeleteForumCategoryDialog from './dialogs/DeleteForumCategoryDialog';
import ArchiveForumCategoryDialog from './dialogs/ArchiveForumCategoryDialog';
import groupForumsConstants from '../constants/groupForumsConstants';

export type GroupForumsCategoryConfigSectionProps = {
  group: Group;
  forumCategory: ForumCategory;
  refetchForumCategories: () => Promise<void>;
  isDeleteDisabled: boolean;
  isArchiveDisabled: boolean;
} & WithTranslationsProps;

const GroupForumsCategoryConfigSection = ({
  group,
  forumCategory,
  refetchForumCategories,
  isDeleteDisabled,
  isArchiveDisabled,
  translate
}: GroupForumsCategoryConfigSectionProps): JSX.Element | null => {
  const [isRenameForumCategoryDialogVisible, setIsRenameForumCategoryDialogVisible] = useState(
    false
  );
  const [isDeleteForumCategoryDialogVisible, setIsDeleteForumCategoryDialogVisible] = useState(
    false
  );
  const [isArchiveForumCategoryDialogVisible, setIsArchiveForumCategoryDialogVisible] = useState(
    false
  );

  const isArchived = useMemo(() => {
    return forumCategory.archivedBy != null;
  }, [forumCategory.archivedBy]);

  const showRenameButton = useMemo(() => {
    return !isArchived;
  }, [isArchived]);

  const showArchiveButton = useMemo(() => {
    return !isArchiveDisabled;
  }, [isArchiveDisabled]);

  const showDeleteButton = useMemo(() => {
    return !isDeleteDisabled;
  }, [isDeleteDisabled]);

  const showRenameForumCategoryDialog = useCallback(() => {
    setIsRenameForumCategoryDialogVisible(true);
  }, [setIsRenameForumCategoryDialogVisible]);

  const hideRenameForumCategoryDialog = useCallback(() => {
    setIsRenameForumCategoryDialogVisible(false);
  }, [setIsRenameForumCategoryDialogVisible]);

  const showDeleteForumCategoryDialog = useCallback(() => {
    setIsDeleteForumCategoryDialogVisible(true);
  }, [setIsDeleteForumCategoryDialogVisible]);

  const hideDeleteForumCategoryDialog = useCallback(() => {
    setIsDeleteForumCategoryDialogVisible(false);
  }, [setIsDeleteForumCategoryDialogVisible]);

  const showArchiveForumCategoryDialog = useCallback(() => {
    setIsArchiveForumCategoryDialogVisible(true);
  }, [setIsArchiveForumCategoryDialogVisible]);

  const hideArchiveForumCategoryDialog = useCallback(() => {
    setIsArchiveForumCategoryDialogVisible(false);
  }, [setIsArchiveForumCategoryDialogVisible]);

  return (
    <React.Fragment>
      {isRenameForumCategoryDialogVisible && (
        <CreateOrUpdateForumCategoryDialog
          groupId={group.id}
          forumCategory={forumCategory}
          onSuccess={refetchForumCategories}
          onClose={hideRenameForumCategoryDialog}
        />
      )}
      {isDeleteForumCategoryDialogVisible && (
        <DeleteForumCategoryDialog
          groupId={group.id}
          forumCategory={forumCategory}
          onSuccess={refetchForumCategories}
          onClose={hideDeleteForumCategoryDialog}
        />
      )}
      {isArchiveForumCategoryDialogVisible && (
        <ArchiveForumCategoryDialog
          groupId={group.id}
          forumCategory={forumCategory}
          onSuccess={refetchForumCategories}
          onClose={hideArchiveForumCategoryDialog}
        />
      )}
      <div className='group-forums-config-category border-bottom'>
        <div className='group-forums-config-category-name font-bold'>{forumCategory.name}</div>
        <div className='group-forums-config-category-icons'>
          <Popover
            id='group-event-dropdown-menu-popover'
            button={
              <IconButton
                iconName='more'
                size={IconButton.sizes.small}
                onClick={groupForumsConstants.noOpFunctionRef}
              />
            }
            trigger='click'
            placement='bottom'>
            <ul className='group-forums-config-dropdown-menu dropdown-menu' role='menu'>
              {showRenameButton && (
                <li>
                  <button
                    type='button'
                    className='btn-secondary-md btn-min-width group-forums-config-dialog-button'
                    onClick={showRenameForumCategoryDialog}>
                    {translate('Action.RenameForumCategory')}
                  </button>
                </li>
              )}
              {showArchiveButton && (
                <li>
                  <button
                    type='button'
                    className='btn-secondary-md btn-min-width group-forums-config-dialog-button'
                    onClick={showArchiveForumCategoryDialog}>
                    {translate(
                      isArchived ? 'Action.UnarchiveForumCategory' : 'Action.ArchiveForumCategory'
                    )}
                  </button>
                </li>
              )}
              {showDeleteButton && (
                <li>
                  <button
                    type='button'
                    className='btn-secondary-md btn-min-width group-forums-config-dialog-button'
                    onClick={showDeleteForumCategoryDialog}>
                    {translate('Action.DeleteForumCategory')}
                  </button>
                </li>
              )}
            </ul>
          </Popover>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withTranslations(GroupForumsCategoryConfigSection, groupsConfig);
