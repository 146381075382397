import React from 'react';
import { withTranslations, WithTranslationsProps } from 'react-utilities';
import { groupsConfig } from '../translation.config';
import { CommentVariants } from '../constants/groupForumsConstants';
import CommentSkeleton from '../components/skeletons/CommentSkeleton';
import InfiniteLoader from '../components/InfiniteLoader';
import Comment from '../components/Comment';
import { usePost } from '../contexts/PostContext';
import { useComposer } from '../contexts/ComposerContext';
import ForumSectionDisclaimer from '../components/ForumSectionDisclaimer';
import useForumStore from '../hooks/useForumStore';

const CommentsSection = ({ translate }: WithTranslationsProps): JSX.Element => {
  const {
    refetchComments,
    comments,
    fetchNextCommentsPage,
    fetchPreviousCommentsPage,
    isFetchingNextCommentsPage,
    isFetchingPreviousCommentsPage,
    isLoadingComments,
    post,
    errorLoadingComments,
    hasNextComments,
    hasPreviousComments
  } = usePost();
  const { highlightedCommentId } = useComposer();
  const blockedUserList = useForumStore.use.blockedUserList();
  const activeCommentId = useForumStore.use.activeCommentId();
  if (errorLoadingComments) {
    return (
      <ForumSectionDisclaimer
        iconClassName='icon-status-alert'
        heading={translate('Error.LoadCommentsTitle')}
        message={translate('Error.ReloadingSubtitle')}
        buttonText={translate('Action.RetryLoadingComments')}
        onClick={refetchComments}
      />
    );
  }

  if (isLoadingComments || !post) {
    return (
      <div className='group-forums-comments-section group-forums-comments-section-loading'>
        <CommentSkeleton />
        <CommentSkeleton />
        <CommentSkeleton />
      </div>
    );
  }

  if (comments.length <= 1) {
    const iconClassName = post?.isLocked ? 'locked-status-icon' : 'chat-side-icon';
    const heading = post?.isLocked
      ? translate('Label.LockedForumPost')
      : translate('Label.NoCommentsFoundHeader');
    const message = post?.isLocked
      ? translate('Message.LockedForumPostComment')
      : translate('Label.NoCommentsFoundText');
    return (
      <ForumSectionDisclaimer iconClassName={iconClassName} heading={heading} message={message} />
    );
  }

  const canLoadTop = hasPreviousComments && !isFetchingPreviousCommentsPage;
  const canLoadBottom = hasNextComments && !isFetchingNextCommentsPage;

  return (
    <div className='group-forums-comments-section'>
      {canLoadTop && (
        <div className='group-forums-comments-section-loader-top'>
          {!activeCommentId && ( // don't interrupt deep-link scrolling position by loading more comments
            <InfiniteLoader onLoadMore={fetchPreviousCommentsPage} viewingThreshold={1} />
          )}
        </div>
      )}
      {isFetchingPreviousCommentsPage && (
        <div className='group-forums-comments-section-spinner spinner spinner-default spinner-infinite-scroll' />
      )}
      {comments.map(comment => {
        if (comment.id === post.firstComment.id) {
          return null;
        }
        if (blockedUserList.length > 0 && blockedUserList.includes(comment.createdBy)) {
          return null;
        }
        return (
          <Comment
            key={comment.id}
            id={comment.id}
            createdBy={comment.createdBy}
            creatorInfo={comment.creatorInfo}
            createdAt={comment.createdAt}
            updatedAt={comment.updatedAt}
            content={comment.content.plainText}
            threadId={comment.threadId}
            channelId={comment.parentId}
            variant={CommentVariants.Comment}
            isActive={highlightedCommentId === comment.id}
            reactions={comment.reactions}
            initialThreadComments={comment.threadComments}
          />
        );
      })}
      {canLoadBottom && (
        <div className='group-forums-comments-section-loader-bottom'>
          <InfiniteLoader onLoadMore={fetchNextCommentsPage} viewingThreshold={1} />
        </div>
      )}
      {isFetchingNextCommentsPage && (
        <div className='group-forums-comments-section-spinner spinner spinner-default spinner-infinite-scroll' />
      )}
    </div>
  );
};
export default withTranslations(CommentsSection, groupsConfig);
