import React, { useCallback, useEffect, useMemo } from 'react';
import { useSystemFeedback } from 'react-style-guide';
import { withTranslations, WithTranslationsProps } from 'react-utilities';
import classNames from 'classnames';
import { InfiniteData, useInfiniteQuery, useQuery, useQueryClient } from '@tanstack/react-query';
import { ForumPost, ForumPostsResponse, NotificationPreferenceType } from '../types';
import { groupsConfig } from '../translation.config';
import forumsService from '../services/forumsService';
import PostPreview from '../components/PostPreview';
import PostPreviewSkeleton from '../components/skeletons/PostPreviewSkeleton';
import groupForumsConstants from '../constants/groupForumsConstants';
import InfiniteLoader from '../components/InfiniteLoader';
import { useForumPermissions } from '../contexts/ForumPermissionsContext';
import { ComparePost } from '../utils/typeComparison';
import ForumSectionDisclaimer from '../components/ForumSectionDisclaimer';
import useForumStore from '../hooks/useForumStore';
import { getCategoryPinnedPostsKey, getCategoryPostsKey } from '../services/queryKeys';

export type PostPreviewListProps = {} & WithTranslationsProps;

const PostPreviewList = ({ translate }: PostPreviewListProps): JSX.Element => {
  const groupId = useForumStore.use.groupId();
  const categoryId = useForumStore.use.categoryId() || '';
  const isReady = useMemo(() => !!(groupId && categoryId), [categoryId, groupId]);
  const queryClient = useQueryClient();
  const { systemFeedbackService } = useSystemFeedback();
  const { canCreatePost } = useForumPermissions();

  // load category pinned posts
  const {
    isLoading: isLoadingPinnedPosts,
    data: pinnedCategoryResponse,
    refetch: refetchPinnedCategoryPosts
  } = useQuery({
    queryKey: getCategoryPinnedPostsKey(groupId, categoryId),
    queryFn: async ({ queryKey }) => {
      const [, queryGroupId, queryCategoryId] = queryKey;
      return forumsService.getGroupForumPinnedPosts(queryGroupId, queryCategoryId);
    },
    onError: () => systemFeedbackService.warning(translate('NetworkError')),
    enabled: isReady
  });

  // infinite load category posts
  const {
    isLoading,
    isFetchingNextPage,
    data: categoryPostResponse,
    fetchNextPage: fetchNextPostPage,
    error: errorLoadingPosts,
    refetch: refetchCategoryPosts
  } = useInfiniteQuery({
    queryKey: getCategoryPostsKey(groupId, categoryId),
    queryFn: async ({ queryKey, pageParam: cursor }) => {
      const [, queryGroupId, queryCategoryId] = queryKey;
      const response = await forumsService.getGroupForumPosts(
        queryGroupId,
        queryCategoryId,
        groupForumsConstants.pageCounts.postsPerPage,
        cursor
      );

      // filter pinned posts
      response.data = response.data.filter(post => !post.isPinned);
      return response;
    },
    getNextPageParam: (lastPage: ForumPostsResponse) => lastPage.nextPageCursor || undefined,
    enabled: isReady
  });

  const pinnedCategoryPosts = useMemo(() => pinnedCategoryResponse?.data || [], [
    pinnedCategoryResponse
  ]);

  const categoryPosts = useMemo(() => {
    const pages = categoryPostResponse ? categoryPostResponse.pages : [];
    return pages.reduce((acc, response) => acc.concat(response.data), [] as ForumPost[]);
  }, [categoryPostResponse]);

  const updatePost = useCallback(
    (post: ForumPost) => {
      // first check pinned posts
      const updatedPinnedItemIndex = pinnedCategoryResponse?.data.findIndex(item =>
        ComparePost(item, post)
      );

      if (updatedPinnedItemIndex) {
        // update pinned posts
        const updatedList =
          pinnedCategoryResponse?.data.map(item => (ComparePost(item, post) ? post : item)) ?? [];
        queryClient.setQueryData(getCategoryPinnedPostsKey(groupId, categoryId), () => updatedList);
      } else {
        // update category lists
        const updatedList: ForumPostsResponse[] =
          categoryPostResponse?.pages.map(page => ({
            ...page,
            data: page.data.map(item => (ComparePost(item, post) ? post : item))
          })) || [];

        queryClient.setQueryData(
          getCategoryPostsKey(groupId, categoryId),
          (data: InfiniteData<ForumPostsResponse> | undefined) =>
            ({
              pages: updatedList,
              pageParams: data?.pageParams
            } as InfiniteData<ForumPostsResponse>)
        );
      }
    },
    [pinnedCategoryResponse, categoryPostResponse, groupId, categoryId, queryClient]
  );

  const refetchAllPosts = useCallback(() => {
    // eslint-disable-next-line no-void
    void refetchCategoryPosts();
    // eslint-disable-next-line no-void
    void refetchPinnedCategoryPosts();
  }, [refetchPinnedCategoryPosts, refetchCategoryPosts]);

  const togglePostNotifications = useCallback(
    async (post: ForumPost) => {
      try {
        const newIsSubscribed =
          !post.notificationPreference ||
          post.notificationPreference === NotificationPreferenceType.None;
        await forumsService.togglePostNotificationSubscription(
          groupId,
          categoryId,
          post.id,
          newIsSubscribed
        );

        const updatedPost = {
          ...post,
          notificationPreference: newIsSubscribed
            ? NotificationPreferenceType.All
            : NotificationPreferenceType.None
        };
        updatePost(updatedPost);

        systemFeedbackService.success(translate('Message.NotificationPreferenceUpdated'));
      } catch {
        systemFeedbackService.warning(translate('NetworkError'));
      }
    },
    [groupId, categoryId, systemFeedbackService, updatePost, translate]
  );

  useEffect(() => {
    if (!errorLoadingPosts) return;
    systemFeedbackService.warning(translate('NetworkError'));
  }, [errorLoadingPosts, systemFeedbackService, translate]);

  if (errorLoadingPosts) {
    return (
      <ForumSectionDisclaimer
        className='group-forums-posts-list-no-post'
        iconClassName='icon-status-alert'
        heading={translate('Error.LoadCategoryTitle')}
        message={translate('Error.ReloadingSubtitle')}
        buttonText={translate('Action.RetryLoadingPosts')}
        onClick={refetchAllPosts}
      />
    );
  }

  if (isLoading || isLoadingPinnedPosts) {
    return (
      <div className='group-forums-posts-list group-forums-posts-list-skeleton'>
        <PostPreviewSkeleton />
        <PostPreviewSkeleton />
        <PostPreviewSkeleton />
      </div>
    );
  }

  if (!categoryPosts.length && !pinnedCategoryPosts.length) {
    return (
      <ForumSectionDisclaimer
        className='group-forums-posts-list-no-post'
        iconClassName='chat-side-icon'
        heading={translate('Label.NoPostsFoundHeader')}
        message={translate('Label.NoPostsFoundText')}
      />
    );
  }

  return (
    <div
      className={classNames('group-forums-posts-list', {
        'group-forums-posts-list-with-footer': canCreatePost
      })}>
      <div className='group-forums-post-list-content'>
        {pinnedCategoryPosts.map(post => {
          return (
            <PostPreview
              showPinned
              key={`${post.id}_pinned`}
              post={post}
              refetchPosts={refetchAllPosts}
              togglePostNotifications={() => togglePostNotifications(post)}
            />
          );
        })}
        {categoryPosts.map(post => {
          return (
            <PostPreview
              showPinned={false}
              key={post.id}
              post={post}
              refetchPosts={refetchAllPosts}
              togglePostNotifications={() => togglePostNotifications(post)}
            />
          );
        })}
        <InfiniteLoader onLoadMore={fetchNextPostPage} viewingThreshold={1} />
        {isFetchingNextPage && <div className='spinner spinner-default spinner-infinite-scroll' />}
      </div>
    </div>
  );
};

export default withTranslations(PostPreviewList, groupsConfig);
