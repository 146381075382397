import { match as ReactRouterMatch } from 'react-router-dom';
import { ForumCategory } from '../types';

import groupForumsConstants from '../constants/groupForumsConstants';
import forumsService from '../services/forumsService';
import {
  isTopLevelComment,
  isThreadedComment,
  getThreadPostAndComment
} from '../utils/channelMessageHelper';
import { State } from './sliceTypes';

type RouteMatchType =
  | {
      categoryId: string;
    }
  | {
      categoryId: string;
      postId: string;
    }
  | {
      categoryId: string;
      postId: string;
      commentId: string;
    };

const getValidCategoryId = (categoryId: string, categories: ForumCategory[]): string => {
  const nextCategoryId = categories[0].id;
  const existing = categories.find(category => category.id === categoryId);
  return existing?.id || nextCategoryId;
};

const getCategoryName = (categoryId: string, categories: ForumCategory[]): string => {
  const category = categories.find(c => c.id === categoryId);
  return category?.name || '';
};

type StateUpdate = Partial<State>;

const reduceNextRoute = async (state: State, match: ReactRouterMatch): Promise<StateUpdate> => {
  const { groupId, categories, categoriesLoaded, postId, commentId } = state;
  if (!categoriesLoaded || !categories.length) {
    return {};
  }

  const params = match.params as RouteMatchType;
  const nextCategoryId = getValidCategoryId(params.categoryId, categories);
  const getNextCategoryName = getCategoryName(nextCategoryId, categories);
  switch (match.path) {
    case groupForumsConstants.router.postCommentRoute:
      if (
        'categoryId' in params &&
        'postId' in params &&
        'commentId' in params &&
        commentId !== params.commentId
      ) {
        const nextPostId = params.postId;
        const nextCommentId = params.commentId;
        try {
          const response = await forumsService.getForumAncestry(
            groupId,
            nextCategoryId,
            nextPostId,
            nextCommentId
          );

          const ancestors = response.data;

          if (isTopLevelComment(nextCategoryId, nextPostId, nextCommentId, ancestors)) {
            return {
              categoryId: nextCategoryId,
              categoryName: getNextCategoryName,
              postId: nextPostId,
              commentId: nextCommentId,
              activeCommentId: nextCommentId
            };
          }

          if (isThreadedComment(nextCategoryId, nextPostId, nextCommentId, ancestors)) {
            const { postId: validPostId, commentId: validCommentId } = getThreadPostAndComment(
              ancestors
            );
            return {
              categoryId: nextCategoryId,
              categoryName: getNextCategoryName,
              postId: validPostId,
              commentId: validCommentId,
              threadCommentId: nextCommentId,
              activeCommentId: nextCommentId
            };
          }
        } catch (error) {
          // noop see below
        }

        // we couldn't find this post/comment on ancestors
        return {
          categoryId: nextCategoryId,
          categoryName: getNextCategoryName,
          postId: nextPostId,
          commentId: nextCommentId
        };
      }
      break;
    case groupForumsConstants.router.postRoute:
      if ('categoryId' in params && 'postId' in params && postId !== params.postId) {
        return {
          categoryId: nextCategoryId,
          categoryName: getNextCategoryName,
          postId: params.postId,
          commentId: undefined
        };
      }
      break;
    case groupForumsConstants.router.categoryRoute:
      if ('categoryId' in params) {
        return {
          categoryId: nextCategoryId,
          categoryName: getNextCategoryName,
          postId: undefined,
          commentId: undefined
        };
      }
      break;
    case groupForumsConstants.router.defaultRoute:
    default:
      return {
        categoryId: nextCategoryId,
        categoryName: getNextCategoryName,
        postId: undefined,
        commentId: undefined
      };
  }

  return {};
};

export default reduceNextRoute;
