const validateStringLength = (
  value: string,
  errorKey: string,
  min = 0,
  max = Infinity,
  trimWhiteSpace = true,
  ignoreEmpty = true
): string | undefined => {
  if (value === undefined) return undefined;
  let newValue = value; // Technically not needed since javascript strings are immutable, but TS complains if we don't do this
  if (trimWhiteSpace) newValue = value.trim();
  if (ignoreEmpty && (newValue == null || newValue.length === 0)) return undefined;
  if (newValue.length >= min && newValue.length <= max) return undefined;
  return errorKey;
};

export default { validateStringLength };
