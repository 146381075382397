import React, { useEffect } from 'react';
import { withTranslations, WithTranslationsProps } from 'react-utilities';
import { createModal, useSystemFeedback } from 'react-style-guide';
import userBlockingService from '../../services/userBlockingService';
import { groupsConfig } from '../../translation.config';
import useForumStore from '../../hooks/useForumStore';

const BlockUserModal = ({ translate }: WithTranslationsProps): JSX.Element | null => {
  const blockUserDialogContext = useForumStore.use.blockUserDialogContext();
  const closeBlockDialog = useForumStore.use.closeBlockDialog();
  const blockUser = useForumStore.use.blockUser();
  const [BlockModal, modalService] = createModal();
  const { systemFeedbackService } = useSystemFeedback();
  useEffect(() => {
    if (blockUserDialogContext.userId) {
      modalService.open();
    } else {
      modalService.close();
    }
  }, [modalService, blockUserDialogContext.userId]);

  if (!blockUserDialogContext.userId) {
    return null;
  }

  const blockUserAction = async () => {
    if (blockUserDialogContext?.userId) {
      try {
        await userBlockingService.blockUser(blockUserDialogContext.userId);
        // dispatch an add for blocking a user.
        systemFeedbackService.success(translate('Message.BlockUserSuccess'));
        blockUser(blockUserDialogContext.userId);
      } catch (e) {
        systemFeedbackService.warning(translate('NetworkError'));
      }

      closeBlockDialog();
    }
  };

  const hideUserBlockModal = () => {
    closeBlockDialog();
  };

  return (
    <BlockModal
      footerText={translate('Description.BlockUserFooterDialog')}
      title={translate('Heading.BlockUserWarning')}
      onClose={hideUserBlockModal}
      body={translate('Description.BlockUserWarning')}
      actionButtonShow
      actionButtonText={translate('Action.BlockUser')}
      onAction={blockUserAction}
      neutralButtonText={translate('Action.Cancel')}
      onNeutral={hideUserBlockModal}
    />
  );
};

export default withTranslations(BlockUserModal, groupsConfig);
