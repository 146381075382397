import React, { useCallback, useMemo } from 'react';
import { Popover, useSystemFeedback } from 'react-style-guide';
import { withTranslations, WithTranslationsProps } from 'react-utilities';
import { InfiniteData, useQueryClient } from '@tanstack/react-query';
import { groupsConfig } from '../translation.config';
import groupForumsConstants from '../constants/groupForumsConstants';
import { useForumPermissions } from '../contexts/ForumPermissionsContext';
import DropdownMenuItem from './DropdownMenuItem';
import { usePost } from '../contexts/PostContext';
import { useComposer } from '../contexts/ComposerContext';
import { ForumCommentsResponse, NotificationPreferenceType } from '../types';
import useForumStore from '../hooks/useForumStore';
import { getCommentRepliesKey } from '../services/queryKeys';

export type CommentMenuProps = {
  createdBy: number;
  commentId: string;
  button: JSX.Element;
  isReply: boolean;
  parentCommentId?: string;
  threadId: string | null;
  channelId: string;
  onSubscribe: () => void;
} & WithTranslationsProps;

const CommentMenu = ({
  createdBy,
  commentId,
  button,
  isReply,
  parentCommentId,
  channelId,
  onSubscribe,
  translate
}: CommentMenuProps): JSX.Element => {
  const { canEditComment, canDeleteComment, canBlockUser, canSubscribe } = useForumPermissions();
  const groupId = useForumStore.use.groupId();
  const categoryId = useForumStore.use.categoryId() || '';
  const postId = useForumStore.use.postId() || '';
  const openBlockDialog = useForumStore.use.openBlockDialog();
  const { comments, handleDeleteComment } = usePost();
  const { setEditComment } = useComposer();
  const { systemFeedbackService } = useSystemFeedback();
  const queryClient = useQueryClient();

  const showEditComment = useMemo(() => canEditComment(createdBy), [canEditComment, createdBy]);
  const showBlockUser = useMemo(() => canBlockUser(createdBy), [canBlockUser, createdBy]);
  const showDeleteComment = useMemo(() => canDeleteComment(createdBy), [
    canDeleteComment,
    createdBy
  ]);

  // we have no ETA for launching subscribing to comments, so decouple from canSubscribe to be safe
  const showSubscribe = false; // useMemo(() => canSubscribe(createdBy), [canSubscribe, createdBy]);

  const handleEditComment = useCallback(() => {
    setEditComment(commentId, parentCommentId);
  }, [commentId, parentCommentId, setEditComment]);

  const onDeleteComment = useCallback(async () => {
    const success = await handleDeleteComment(commentId, parentCommentId);
    if (!success) {
      systemFeedbackService.warning(translate('NetworkError'));
    }

    // remove from query cache
    if (isReply) {
      const parentComment = comments.find(c => c.id === parentCommentId);
      const queryKey = getCommentRepliesKey(groupId, categoryId, parentComment?.threadId || '');
      const repliesCache = queryClient.getQueryData<InfiniteData<ForumCommentsResponse>>(queryKey);
      if (repliesCache) {
        const updatedList: ForumCommentsResponse[] =
          repliesCache.pages.map(page => ({
            ...page,
            data: page.data.filter(val => val.id !== commentId)
          })) ?? [];
        queryClient.setQueryData(
          queryKey,
          (data: InfiniteData<ForumCommentsResponse> | undefined) =>
            ({
              pages: updatedList,
              pageParams: data?.pageParams
            } as InfiniteData<ForumCommentsResponse>)
        );
      }
    }
  }, [
    handleDeleteComment,
    commentId,
    parentCommentId,
    systemFeedbackService,
    translate,
    isReply,
    groupId,
    categoryId,
    queryClient,
    comments
  ]);

  const handleCopyLink = useCallback(async () => {
    if (navigator.clipboard) {
      try {
        const commentUrl = groupForumsConstants.deepLinks.groupForumCommentUrl(
          groupId,
          categoryId,
          isReply ? channelId : postId,
          commentId
        );
        await navigator.clipboard.writeText(commentUrl);
        systemFeedbackService.success(translate('Label.LinkCopied'));
      } catch {
        systemFeedbackService.warning(translate('Error.CopyLink'));
      }
    }
  }, [
    categoryId,
    commentId,
    groupId,
    postId,
    systemFeedbackService,
    translate,
    isReply,
    channelId
  ]);

  const handleReport = useCallback(() => {
    const reportUrl = groupForumsConstants.urls.reportAbuse(
      groupId,
      postId,
      channelId,
      commentId,
      true
    );
    window.location.href = reportUrl;
  }, [commentId, groupId, postId, channelId]);

  const [isSubscribed, isSubscriptionLoaded] = useMemo(() => {
    const comment = comments.find(c => c.id === commentId);
    return [
      comment?.notificationPreference === NotificationPreferenceType.All,
      comment?.notificationPreference !== undefined
    ];
  }, [comments, commentId]);

  const onBlockUser = () => {
    openBlockDialog(createdBy);
  };

  return (
    <Popover
      id='group-forums-comment-dropdown-menu'
      button={button}
      trigger='click'
      placement='bottom'>
      <ul className='dropdown-menu' role='menu'>
        {showEditComment && (
          <DropdownMenuItem
            translateKey={isReply ? 'Label.EditReply' : 'Label.EditComment'}
            action={handleEditComment}
          />
        )}
        {showDeleteComment && (
          <DropdownMenuItem
            translateKey={isReply ? 'Label.DeleteReply' : 'Label.DeleteComment'}
            action={onDeleteComment}
          />
        )}
        {navigator.clipboard && (
          <DropdownMenuItem translateKey='Label.CopyLink' action={handleCopyLink} />
        )}
        {showBlockUser && <DropdownMenuItem translateKey='Label.BlockUser' action={onBlockUser} />}
        {showSubscribe && (
          <DropdownMenuItem
            translateKey={
              isSubscribed ? 'Label.UnsubscribeFromComment' : 'Label.SubscribeToComment'
            }
            action={onSubscribe}
            disabled={!isSubscriptionLoaded}
          />
        )}
        <DropdownMenuItem translateKey='Label.ReportAbuse' action={handleReport} />
      </ul>
    </Popover>
  );
};

export default withTranslations(CommentMenu, groupsConfig);
