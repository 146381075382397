import React from 'react';
import { withTranslations, WithTranslationsProps } from 'react-utilities';
import { groupsConfig } from '../translation.config';

export type CreatePostButtonProps = {
  label: string;
  disabled?: boolean;
  onClick: (() => void) | (() => Promise<any>);
} & WithTranslationsProps;

const CreatePostButton = ({
  label,
  disabled,
  onClick,
  translate
}: CreatePostButtonProps): JSX.Element => (
  <button
    type='button'
    className='group-forums-create-post-btn btn-growth-md'
    onClick={onClick}
    title={disabled ? translate('Description.NoPostPermission') : undefined}
    disabled={disabled}>
    <span className='icon-plus' />
    {label}
  </button>
);

CreatePostButton.displayName = 'CreatePostButton';

export default withTranslations(CreatePostButton, groupsConfig);
