interface Channel {
  id: string;
  groupId: number;
  name: string;
  description: string | null;
  channelType: string;
  parentChannelId?: string;
  createdBy: number;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  archivedAt: string | null;
  archivedBy: number | null;
}
interface ForumCategory extends Channel {
  description: string;
}

interface CommentContent {
  plainText: string;
}

interface CommentCreatorInfo {
  displayName: string;
  hasVerifiedBadge: boolean;
  groupRoleName: string;
}

interface Reaction {
  emoteId: string;
  reactionCount: number;
  hasUserAppliedReaction: boolean;
  areReactionCountsVisible: boolean;
}

interface ForumComment {
  id: string;
  parentId: string;
  content: CommentContent;
  createdBy: number;
  createdAt: string;
  creatorInfo: CommentCreatorInfo;
  updatedAt: string;
  deletedAt: string | null;
  threadId: string | null;
  reactions: Reaction[];
  replies: ForumComment[];
  notificationPreference?: NotificationPreferenceType;
  threadComments: ForumThreadCommentsResponse | null;
  threadCommentCount: number | null;
}
interface ForumPost extends Channel {
  categoryId: string;
  name: string;
  isLocked: boolean;
  isPinned: boolean;
  isUnread: boolean;
  lastComment: ForumComment;
  firstComment: ForumComment;
  commentCount: number;
  notificationPreference?: NotificationPreferenceType;
}

interface ForumsResponse<T> {
  data: T[];
  nextPageCursor: string;
  previousPageCursor: string;
}

interface ForumAncestry {
  channelId: string;
  commentId?: string;
  channel: Channel;
  comment?: ForumComment;
}

type ForumPostsResponse = ForumsResponse<ForumPost>;
type ForumCommentsResponse = ForumsResponse<ForumComment>;
type ForumCategoriesResponse = ForumsResponse<ForumCategory>;
type ForumAncestryResponse = ForumsResponse<ForumAncestry>;
interface ForumThreadCommentsResponse {
  comments: ForumComment[];
  nextPageCursor: string;
  previousPageCursor: string;
  hasMore: boolean;
}

interface PostState {
  post: ForumPost | null;
  isLoadingPost: boolean;
  loadingPostError: boolean;
  fetchPost: () => void;
  handleCreateComment: (args: {
    content: string;
    parentCommentId?: string;
    mentioningReplyId?: string;
  }) => Promise<void>;
  handleEditComment: (args: {
    content: string;
    parentCommentId?: string;
    commentId: string;
  }) => Promise<void>;
  isLoadingComments: boolean;
  hasNextComments: boolean;
  hasPreviousComments: boolean;
  refetchComments: () => void;
  isFetchingNextCommentsPage: boolean;
  isFetchingPreviousCommentsPage: boolean;
  fetchNextCommentsPage: () => void;
  fetchPreviousCommentsPage: () => void;
  fetchPostNotificationPreference: () => void;
  fetchCommentNotificationPreference: (commentId: string) => void;
  togglePostNotifications: () => void;
  toggleCommentNotifications: (commentId: string) => void;
  errorLoadingComments: boolean;
  comments: ForumComment[];
  getComment: (commentId: string, parentCommentId?: string) => ForumComment | null;
  handleDeleteComment: (commentId: string, parentCommentId?: string) => Promise<boolean>;
}

interface ForumPermissionsState {
  canCreatePost: boolean;
  canCreateComment: boolean;
  canPinPost: boolean;
  canLockPost: boolean;
  canEditPost: (authorId: number) => boolean;
  canEditComment: (authorId: number) => boolean;
  canDeletePost: (authorId: number) => boolean;
  canDeleteComment: (authorId: number) => boolean;
  canBlockUser: (authorId: number) => boolean;
  canReact: boolean;
  canSubscribe: (authorId: number) => boolean;
}

enum NotificationPreferenceType {
  Invalid = 0,
  None = 1,
  All = 2,
  Personalized = 3,
  StrictPersonalized = 4
}

interface NotificationPreference {
  preference: NotificationPreferenceType;
}

interface ForumNotificationsExperimentConfig {
  receivePostCreatorNotifications: boolean;
  receiveCommentCreatorNotifications: boolean;
  receiveSubscriberNotifications: boolean;
  throttleTimeMs: number;
}

interface ForumExperimentsState {
  notificationsExperimentConfig: ForumNotificationsExperimentConfig | null;
}

export {
  ForumCategory,
  ForumCategoriesResponse,
  ForumPostsResponse,
  ForumAncestryResponse,
  ForumPost,
  Reaction,
  ForumAncestry,
  ForumComment,
  CommentContent,
  CommentCreatorInfo,
  ForumCommentsResponse,
  ForumThreadCommentsResponse,
  PostState,
  ForumPermissionsState,
  NotificationPreference,
  NotificationPreferenceType,
  ForumNotificationsExperimentConfig,
  ForumExperimentsState
};
