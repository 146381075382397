import React, { useCallback, useEffect, useRef } from 'react';
import { useSystemFeedback } from 'react-style-guide';
import { withTranslations, WithTranslationsProps } from 'react-utilities';
import { useHistory } from 'react-router-dom';
import { groupsConfig } from '../translation.config';
import PostSkeleton from '../components/PostSkeleton';
import CommentsSection from './CommentsSection';
import SectionHeader from '../components/SectionHeader';
import groupForumsConstants, { CommentVariants } from '../constants/groupForumsConstants';
import Comment from '../components/Comment';
import { PostProvider, usePost } from '../contexts/PostContext';
import { ComposerProvider, useComposer } from '../contexts/ComposerContext';
import { useForumPermissions } from '../contexts/ForumPermissionsContext';
import PostNavigation from '../components/PostNavigation';
import useForumStore from '../hooks/useForumStore';
import CommentComposer from '../components/CommentComposer';
import DesktopPersistentComposer from '../components/DesktopPersistentComposer';
import ForumSectionDisclaimer from '../components/ForumSectionDisclaimer';
import usePostScrollEffects from '../hooks/usePostScrollEffects';

export type PostProps = {} & WithTranslationsProps;

const Post = ({ translate }: WithTranslationsProps): JSX.Element => {
  const history = useHistory();

  const { isLoadingPost, post, loadingPostError, fetchPost } = usePost();
  const { highlightedCommentId } = useComposer();
  const blockedUserList = useForumStore.use.blockedUserList();
  const categoryId = useForumStore.use.categoryId() || '';
  const categoryName = useForumStore.use.categoryName() || '';
  const useInlineReply = useForumStore.use.useInlineReply();

  const { canCreateComment } = useForumPermissions();
  const { SystemFeedbackComponent } = useSystemFeedback();

  const postScrollContainerRef = useRef<HTMLDivElement>(null);

  usePostScrollEffects({ postScrollContainerRef });

  useEffect(() => {
    // If we are on the page of a post which you blocked a user, navigate away from it.
    if (post?.createdBy && blockedUserList.includes(post.createdBy)) {
      history.replace(groupForumsConstants.router.getCategoryRoute(categoryId));
    }
  }, [blockedUserList, post?.createdBy, categoryId, history]);

  const showNativeCommentComposer = canCreateComment && !post?.isLocked && !useInlineReply;

  const onBack = useCallback(() => {
    history.push(groupForumsConstants.router.getCategoryRoute(categoryId));
  }, [categoryId, history]);

  useEffect(() => {
    if (!isLoadingPost && !post && !loadingPostError) {
      onBack();
    }
  }, [isLoadingPost, loadingPostError, onBack, post]);

  const renderPostContent = () => {
    if (loadingPostError) {
      return (
        <ForumSectionDisclaimer
          iconClassName='icon-status-alert'
          heading={translate('Error.LoadPostTitle')}
          message={translate('Error.ReloadingSubtitle')}
          buttonText={translate('Action.RetryLoadingPost')}
          onClick={fetchPost}
        />
      );
    }

    if (isLoadingPost || !post) {
      return <PostSkeleton />;
    }

    return (
      <Comment
        title={post.name}
        variant={CommentVariants.Post}
        isActive={highlightedCommentId === post.firstComment.id}
        id={post.firstComment.id}
        createdBy={post.firstComment.createdBy}
        createdAt={post.firstComment.createdAt}
        updatedAt={post.firstComment.updatedAt}
        creatorInfo={post.firstComment.creatorInfo}
        content={post.firstComment.content.plainText}
        threadId={null}
        channelId={post.firstComment.parentId}
        reactions={post.firstComment.reactions}
      />
    );
  };

  return (
    <div className='group-forums-post'>
      <PostNavigation
        categoryName={categoryName}
        backRoute={groupForumsConstants.router.getCategoryRoute(categoryId)}
        postTitle={post?.name}
        containerClassName='hide-on-native'
      />
      <SectionHeader
        headerText={categoryName}
        onBack={onBack}
        containerClassName='show-on-native'
      />
      <div className='group-forums-post-scroll-container' ref={postScrollContainerRef}>
        <div className='group-forums-post-content'>{renderPostContent()}</div>
        {useInlineReply && (
          <div className='group-forums-post-comment-composer'>
            <DesktopPersistentComposer />
          </div>
        )}
        <div className='group-forums-post-divider' />
        <div className='group-forums-post-comments-section'>
          <CommentsSection />
        </div>
      </div>
      {showNativeCommentComposer && (
        <div className='group-forums-native-comment-composer'>
          <CommentComposer showCancelButton={false} />
        </div>
      )}
      <SystemFeedbackComponent />
    </div>
  );
};

const PostWithProvider = (props: PostProps) => (
  <PostProvider {...props}>
    <ComposerProvider>
      <Post {...props} />
    </ComposerProvider>
  </PostProvider>
);
export default withTranslations(PostWithProvider, groupsConfig);
