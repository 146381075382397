type CommentReplyKeyTuple = [key: string, groupId: number, categoryId: string, threadId: string];
export const getCommentRepliesKey = (
  groupId: number,
  categoryId: string,
  threadId: string
): CommentReplyKeyTuple => ['commentReplies', groupId, categoryId, threadId];

type PostKeyTuple = [key: string, groupId: number, categoryId: string];
export const getCategoryPinnedPostsKey = (groupId: number, categoryId: string): PostKeyTuple => [
  'getCategoryPinnedPosts',
  groupId,
  categoryId
];

export const getCategoryPostsKey = (groupId: number, categoryId: string): PostKeyTuple => [
  'getCategoryPosts',
  groupId,
  categoryId
];
